import React from 'react'
import { Carousel, Toast, ActivityIndicator } from 'antd-mobile'
import { action } from 'mobx'
import { observer, useLocalStore } from 'mobx-react-lite'
import qs from 'query-string'
import dayjs from 'dayjs'
import s from './TechDetail.module.scss'
import playBtn2x from '../images/PlayVideoBtn@2x.png'
import playBtn3x from '../images/PlayVideoBtn@3x.png'
import {
  fetchTechDetail,
  changeCollectedStatus,
  fetchComments,
  publishComment,
  changeLikedStatus,
} from '../server-api/forum';
import { useLocation } from 'react-router-dom';
import { parseServerDateTime } from '../utils/date';
import DetailRUActions from '../components/DetailRUActions';
import native from '../utils/native'
import { HeaderIndicator } from '../components/HeaderIndicator';
import DetailBottomPlaceholder from '../components/DetailBottomPlaceholder';
import CommentInputBox from '../components/CommentInputBox';
import TechComment from '../components/TechComment';
import like2x from '../images/like@2x.png'
import like3x from '../images/like@3x.png'
import liked2x from '../images/liked@2x.png'
import liked3x from '../images/liked@3x.png'

const REACH_BOTTOM_THRESHOLD = 200

export default observer(function TechDetail(props) {
  const location = useLocation()
  const query = qs.parse(location.search)
  const id = parseInt(query.id)
  const scrollToComment = query.scrollToComment === 'true'
  const state = useLocalStore(() => {
    return {
      loading: true,
      swipers: [],
      title: '',
      createdAt: new Date(),
      content: '',
      username: '',
      avatar: '',
      levelName: '',
      commentCount: 0,
      collected: false,
      liked: false,
      likedCount: 0,
      inputValue: '',
      nomore: false,
      fetchingComments: false,
      comments: [],
      pageIndex: 1,
      commentingId: 0,
    }
  })
  const pageSize = 10
  let $commentInput = React.useRef(null)
  
  React.useEffect(() => {
    document.title = '普通交流'
      fetchTechDetail(id)
        .then(action(res => {
          const data = res.data.data
          state.swipers = data.uploadFiles.map(item => ({
            image: item.url,
            videoSrc: item.videoUrl,
            isVideo: item.fileType === 3,
            thumbnail: item.thumbnail,
          }))
          state.loading = false
          state.createdAt = parseServerDateTime(data.createAt)
          state.content = data.content
          state.avatar = data.avatar
          state.username = data.name
          state.levelName = data.levelName
          state.commentCount = data.commentCount
          // 收藏状态 1:未收藏 2:已收藏
          state.collected = data.collectionStatus === 2
          // 点赞状态 1:未点赞 2：已点赞
          state.liked = data.likeStatus === 2
          state.likedCount = data.likeCount
        }))
        .catch(e => Toast.info(e.message, 2, null, false))
  }, [])
  React.useEffect(() => {
    const anchor = document.getElementById('commentanchor')
    if (anchor && scrollToComment) {
      setTimeout(() => {
        anchor.scrollIntoView(true)
      }, 100)
    }
  }, [state.loading])
  function refreshComments() {
    if (state.fetchingComments) return
    state.fetchingComments = true
    fetchComments(id, 1, pageSize)
      .then(action(res => {
        const list = res.data.data.list
        state.comments = list
        state.nomore = list.length < pageSize
        state.pageIndex = 2
        document.documentElement.scrollTop = 0
      }))
      .catch(error => Toast.info(error.message, 1.5, null, false))
      .finally(() => {
        state.fetchingComments = false
      })
  }
  function loadMoreComments() {
    if (state.fetchingComments || state.nomore) return
    state.fetchingComments = true
    fetchComments(id, state.pageIndex, pageSize)
      .then(action(res => {
        const list = res.data.data.list
        state.comments = state.comments.concat(list)
        state.nomore = list.length < pageSize
        state.pageIndex += 1
      }))
      .catch(error => Toast.info(error.message, 1.5, null, false))
      .finally(() => {
        state.fetchingComments = false
      })
  }
  React.useEffect(refreshComments, [])
  function onScroll() {
    var d = document.documentElement;
    var offset = (window.scrollY || d.scrollTop) + window.innerHeight + REACH_BOTTOM_THRESHOLD;
    var height = d.offsetHeight;
    
    if (offset >= height) {
      loadMoreComments()
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll')
    }
  }, [])

  if (state.loading) return null
  return (
    <React.Fragment>
      <div className={s.page}>
        <Carousel autoplay={false} infinite>
          {state.swipers.map((item, i) => (
            <div
              className={s.carouseC}
              key={i}
              onClick={action(() => {
                if (!item.isVideo) {
                  native.previewImage(i, state.swipers.map(s => s.image))
                } else {
                  native.playVideo(item.videoSrc);
                }
              })}
            >
              <img className={s.img} src={item.thumbnail} alt="" />
              {item.isVideo && (
                <img
                  className={s.playBtn}
                  src={playBtn2x}
                  srcSet={`${playBtn2x} 2x, ${playBtn3x} 3x`}
                  alt=""
                />
              )}
            </div>
          ))}
        </Carousel>
        <div className={s.main}>
          <div className={s.header}>
            <img className={s.avatar} src={state.avatar} alt="" />
            <div className={s.username}>
              {state.username}
              <div className={s.levelName}>{state.levelName}</div>
            </div>
          </div>
          <div className={s.content}>{state.content}</div>
          <div className={s.createdAt}>
            {dayjs(state.createdAt).format("YYYY/MM/DD")}
          </div>
          <div className={s.likeBar}>
            {state.liked ? (
              <img
                src={liked2x}
                srcSet={`${liked2x} 2x, ${liked3x} 3x`}
                alt=""
                className={s.likeBtn}
                onClick={() => {
                  changeLikedStatus(1, id, false)
                    .then(action(() => {
                      state.likedCount -= 1
                      state.liked = false
                    }))
                }}
              />
            ) : (
              <img
                src={like2x}
                srcSet={`${like2x} 2x, ${like3x} 3x`}
                alt=""
                className={s.likeBtn}
                onClick={() => {
                  changeLikedStatus(1, id, true)
                    .then(action(() => {
                      state.likedCount += 1
                      state.liked = true
                    }))
                }}
              />
            )}
            {state.likedCount}个人已点赞
          </div>
          <DetailRUActions
            collected={state.collected}
            onCollect={() => {
              changeCollectedStatus(1, id, true).then(() => {
                state.collected = true;
              });
            }}
            onShare={() => {}}
            onCancelCollect={() => {
              changeCollectedStatus(1, id, false).then(() => {
                state.collected = false;
              });
            }}
          />
        </div>
        <div className={s.sep}></div>
        <div className={s.comments}>
          <div className={s.commentsHeader}>
            <HeaderIndicator />
            <div id="commentanchor" className={s.commentsCount}>
              全部{state.commentCount}条评论
            </div>
          </div>
          <div className={s.commentsContent}>
            {state.comments.map(comment => (
              <TechComment
                key={comment.id}
                comment={comment}
                onReply={() => {
                  state.commentingId = comment.id;
                  $commentInput.current.focus();
                }}
                onShowDetail={() => {}}
                onReplySubComment={(content, commentId, done) => {
                  return publishComment(id, content, commentId)
                    .then(() => {
                      if (content.length === 0) {
                        Toast.info("请输入评论", 1.5, null, false);
                        return Promise.reject();
                      }
                      done();
                      refreshComments();
                      Toast.info("评论成功", 1.5, null, false);
                    })
                    .catch(error => {
                      Toast.info(error.message, 1.5, null, false);
                    });
                }}
              />
            ))}
            {state.fetchingComments && (
              <div className={s.loading}>
                <ActivityIndicator />
              </div>
            )}
            {state.nomore && (
              <DetailBottomPlaceholder
                showBorder={state.comments.length !== 0}
              />
            )}
          </div>
        </div>
      </div>
      <div className={s.bottomBar}>
        <CommentInputBox
          ref={$commentInput}
          value={state.inputValue}
          onClick={() => {
            // 点击输入框重置评论目标为当前文章
            state.commentingId = 0;
          }}
          onChange={e => {
            state.inputValue = e.currentTarget.value;
          }}
          onSend={() => {
            return publishComment(id, state.inputValue, state.commentingId)
              .then(() => {
                if (state.inputValue.length === 0) {
                  Toast.info("请输入评论", 1.5, null, false);
                  return Promise.reject();
                }
                state.inputValue = "";
                Toast.info("评论成功", 1.5, null, false);
                refreshComments();
              })
              .catch(error => {
                Toast.info(error.message, 1.5, null, false);
              });
          }}
        />
      </div>
    </React.Fragment>
  );
})