import React from 'react'
import classNames from 'classnames'
import { Flex, Icon } from 'antd-mobile'
import { toJS } from 'mobx'
import { useLocalStore, observer } from "mobx-react-lite"
import shallowequal from 'shallowequal'
import s from './upload-images.module.scss'
import { selectFile } from '../utils/select-file';
import { uploadFile, uploadVideoFile } from '../server-api/oss';
import cameraPng from '../images/camera@3x.png'
import videoPng from '../images/video-camera@3x.png'
import { isAndroid } from '../utils/platform';

function UploadImage(props) {
  return (
    <div className={s.image}>
      {
        props.isVideo ?
          <div className={s.video}>视频文件</div> :
          <img className={s.previewImage} src={props.url} alt="" />
      }
      {
        !props.uploaded ?
          <div className={s.uploadMask}>
            {
              props.failed ?
                <div className={s.failed} onClick={props.onRetry}>重新上传</div> :
                <div className={s.progress}>{props.progress}%</div>
            }
          </div> : null
      }
      <div
        className={s.deleteBtn}
        onClick={props.onDelete}
      >
        <Icon
          type="cross-circle"
        />
      </div>
    </div>
  )
}

export default observer(function UploadImages(props, ref) {
  const state = useLocalStore(() => {
    if (props.value) {
      return {
        video: props.value.video,
        images: props.value.images
      }
    }
    return {
      video: null,
      images: [
      ]
    }
  });
  React.useEffect(() => {
    if (props.value) {
      state.video = props.value.video
      state.images = props.value.images
    }
  }, [props.value])
  function runWithMedia(mediaInfo, callback) {
    if (mediaInfo.isVideo && state.video && state.video.id === mediaInfo.id) {
      callback(state.video)
      props.onChange && props.onChange(state)
    } else {
      const i = state.images.findIndex(
        item => item.id === mediaInfo.id
      );
      if (i !== -1) {
        callback(state.images[i])
        props.onChange && props.onChange(state)
      }
    }
  }
  async function uploadMedia(mediaInfo) {
    try {
      runWithMedia(mediaInfo, (media) => {
        media.failed = false
        media.progress = 0
      })
      const api = mediaInfo.isVideo ? uploadVideoFile : uploadFile
      const res = await api(mediaInfo.file, progress => {
        runWithMedia(mediaInfo, (media) => {
          media.progress = progress;
        })
      });
      if (res.data.code === 1000) {
        const ossURL = res.data.data.file;
        runWithMedia(mediaInfo, (media) => {
          media.ossURL = ossURL;
          media.progress = 100;
          media.uploaded = true
        })
      }
    } catch (e) {
      runWithMedia(mediaInfo, (media) => {
        media.failed = true
      })
      console.error(e);
    }
  }

  async function onAddClick(acc) {
    let accept = "video/*,image/*";
    if (state.images.length > 0) {
      console.log('x')
      accept = "image/*";
    }
    if (acc) {
      accept = acc
    }
    const fileInfo = await selectFile(accept);
    const mediaInfo = {
      ossURL: "",
      progress: 0,
      uploaded: false,
      localurl: fileInfo.url,
      name: fileInfo.file.name,
      id: Date.now(),
      isVideo: fileInfo.isVideo,
      failed: false,
      file: fileInfo.file,
    };
    if (mediaInfo.isVideo) {
      state.video = mediaInfo;
      props.onChange && props.onChange(state)
    } else {
      state.images.push(mediaInfo);
      props.onChange && props.onChange(state)
    }
    uploadMedia(mediaInfo)
  }

  return (
    <div ref={ref} className={classNames(s.field, s.mainContent)}>
      <Flex>
        <div className={s.fieldTitle}>
          <span>*</span>视频/图片上传
        </div>
        <div className={s.placeholder}>请上传1个视频/低于6张照片</div>
      </Flex>
      <div className={s.images}>
        {
          state.video ?
            <UploadImage
              url={state.video.localurl}
              progress={state.video.progress}
              key={state.video.id}
              isVideo={state.video.isVideo}
              failed={state.video.failed}
              uploaded={state.video.uploaded}
              onRetry={() => {
                uploadMedia(state.video)
              }}
              onDelete={() => {
                state.video = null
              }}
            /> : null
        }
        {
          state.images.map((item, i) =>
            <UploadImage
              url={item.localurl}
              progress={item.progress}
              key={item.id}
              isVideo={item.isVideo}
              failed={item.failed}
              uploaded={item.uploaded}
              onRetry={() => {
                uploadMedia(item)
              }}
              onDelete={() => {
                state.images.splice(i, 1)
                props.onChange && props.onChange(state)
              }}
            />
          )
        }
        {(!isAndroid && state.video === null && state.images.length < 6) ? (
          <div
            className={s.image}
            onClick={() => onAddClick()}
          >
            <img className={s.camera} src={cameraPng} alt="" />
          </div>
        ) : null}
        {(isAndroid && state.video === null && state.images.length < 6) ? (
          <div
            className={s.image}
            onClick={() => onAddClick('image/*')}
          >
            <img className={s.camera} src={cameraPng} alt="" />
          </div>
        ) : null}
        {(isAndroid && state.video === null && state.images.length === 0) ? (
          <div
            className={s.image}
            onClick={() => onAddClick('video/*')}
          >
            <img className={s.videoCamera} src={videoPng} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}, {
  forwardRef: true,
})