import React from 'react'
import { Carousel, Toast } from 'antd-mobile'
import { action } from 'mobx'
import { observer, useLocalStore } from 'mobx-react-lite'
import qs from 'query-string'
import dayjs from 'dayjs'
import s from './SuppyDetail.module.scss'
import ConfirmDetailButton from '../components/ConfirmDetailButton'
import playBtn2x from '../images/PlayVideoBtn@2x.png'
import playBtn3x from '../images/PlayVideoBtn@3x.png'
import { fetchRegionsOfChina } from '../server-api/common';
import { fetchSupplyDetail, changeCollectedStatus } from '../server-api/forum';
import { useLocation } from 'react-router-dom';
import ForumMachineDetailHeader from '../components/ForumMachineDetailHeader';
import { DetailContact } from '../components/DetailContact';
import { findRegionString } from '../utils/region';
import { hidePhoneNumber } from '../utils/formatter';
import { parseServerDateTime } from '../utils/date';
import DetailBlock from '../components/DetailBlock';
import DetailBottomPlaceholder from '../components/DetailBottomPlaceholder';
import { callUp } from '../utils/capacity';
import DetailRUActions from '../components/DetailRUActions';
import native from '../utils/native'

export default observer(function SupplyDetail(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const state = useLocalStore(() => {
    return {
      loading: true,
      swipers: [
        // {
        //   image: 'https://himg2.huanqiucdn.cn/attachment2010/2020/0110/20200110023424736.jpg',
        //   videoSrc: 'http://vfx.mtime.cn/Video/2019/02/04/mp4/190204084208765161.mp4',
        //   isVideo: true,
        // },
      ],
      title: '',
      subtitle: '',
      view: 0,
      createdAt: new Date(),
      phone: '',
      hidePersonal: true,
      region: '',
      address: '',
      content: '',
      model: '',
      spec: '',
      amount: 0,
      productionTime: '',
      deviceStatus: '',
      duobiModel: '',
      collected: false,
    }
  })
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '供给详情'
    fetchRegionsOfChina()
      .then(r => {
        if (r.code === 1000) {
          setRegions(r.data)
        }

        fetchSupplyDetail(id)
          .then(action(res => {
            console.log(res)
            const data = res.data.data
            state.swipers = data.uploadFiles.map(item => ({
              image: item.url,
              videoSrc: item.videoUrl,
              isVideo: item.fileType === 3,
              thumbnail: item.thumbnail,
            }))
            state.loading = false
            state.title = data.title
            state.subtitle = `转让价格: ${data.expectedSalary}`
            state.phone = data.phone
            state.hidePersonal = data.hidePhone === 1
            state.region = findRegionString(r.data, data.home.split(',').map(i => parseInt(i)))
            state.address = data.location
            state.createdAt = parseServerDateTime(data.createAt)
            state.content = data.content
            state.model = data.brandName + data.model
            state.spec = data.specification
            state.amount = data.number
            state.productionTime = data.productionTime
            state.deviceStatus = data.equipmentStatus === '0' ? '停运' : '运行'
            state.duobiModel = data.multiArmModel
            state.healdFramesNumber = data.healdFramesNumber
            state.warpBeamRatio = data.warpBeamRatio
            state.rollerRollRatio = data.rollerRollRatio
            state.electronicControlModel = data.electronicControlModel
            state.remark = data.remark
            // 收藏状态 1:未收藏 2:已收藏
            state.collected = data.collectionStatus === 2
          }))
          .catch(e => Toast.info(e.message, 2, null, false))
      })
      .catch(e => Toast.info(e.message, 2, null, false))
  }, [])
  
  if (state.loading) return null
  return (
    <React.Fragment>
      <div className={s.page}>
        <Carousel
            autoplay={false}
            infinite
            // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
            // afterChange={index => console.log('slide to', index)}
        >
          {
            state.swipers.map((item, i) =>
              <div
                className={s.carouseC}
                key={i}
                onClick={action(() => {
                  if (!item.isVideo) {
                    native.previewImage(i, state.swipers.map(s => s.image))
                  } else {
                    native.playVideo(item.videoSrc)
                  }
                })}
              >
                <img className={s.img} src={item.thumbnail} alt="" />
                {item.isVideo && <img className={s.playBtn} src={playBtn2x} srcSet={`${playBtn2x} 2x, ${playBtn3x} 3x`} alt="" />}
              </div>
            )
          }
        </Carousel>
        <div style={{
          position: 'relative',
        }}>
          <ForumMachineDetailHeader
            title={state.title}
            subtitle={state.subtitle}
            view={state.view}
            createdAt={state.createdAt}
          />
          <DetailRUActions
            collected={state.collected}
            onCollect={() => {
              changeCollectedStatus(3, id, true)
                .then(() => {
                  state.collected = true
                })
            }}
            onShare={() => {

            }}
            onCancelCollect={() => {
              changeCollectedStatus(3, id, false)
                .then(() => {
                  state.collected = false
                })
            }}
          />
        </div>
        <DetailContact
          entries={[
            {
              key: '电话',
              value: state.phone,
              render(v) {
                const n = state.hidePersonal ? hidePhoneNumber(v) : v
                return <span className={s.phoneNumber}>{n}</span>
              },
            },
            {
              key: '地址',
              value: state.address || '--'
            }
          ]}
        />
        <DetailBlock
          title="基本信息"
        >
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>机型:</div>
              <div className={s.infoValue}>{state.model ? state.model : '--'}</div>
            </div>
            <div className={s.infoItem}>
              <div className={s.infoKey}>规格:</div>
              <div className={s.infoValue}>{state.spec ? state.spec : '--'}</div>
            </div>
          </div>
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>数量:</div>
              <div className={s.infoValue}>{state.amount ? `${state.amount}件` : '--'}</div>
            </div>
            <div className={s.infoItem}>
              <div className={s.infoKey}>生产日期:</div>
              <div className={s.infoValue}>{state.productionTime ? dayjs(state.productionTime).format('YYYY/MM') : '--'}</div>
            </div>
          </div>
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>所在地:</div>
              <div className={s.infoValue}>{state.region ? state.region : '--'}</div>
            </div>
          </div>
        </DetailBlock>
        <DetailBlock
          title="设备状态"
        >
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>设备状态:</div>
              <div className={s.infoValue}>{state.deviceStatus}</div>
            </div>
            <div className={s.infoItem}>
              <div className={s.infoKey}>多臂型号:</div>
              <div className={s.infoValue}>{state.duobiModel ? state.duobiModel : '--'}</div>
            </div>
          </div>
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>综框数量:</div>
              <div className={s.infoValue}>{state.healdFramesNumber ? `${state.healdFramesNumber}件` : '--'}</div>
            </div>
            <div className={s.infoItem}>
              <div className={s.infoKey}>经轴比:</div>
              <div className={s.infoValue}>{state.warpBeamRatio ? state.warpBeamRatio : '--'}</div>
            </div>
          </div>
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>卷布辊比:</div>
              <div className={s.infoValue}>{state.rollerRollRatio ? state.rollerRollRatio : '--'}</div>
            </div>
            <div className={s.infoItem}>
              <div className={s.infoKey}>电控型号:</div>
              <div className={s.infoValue}>{state.electronicControlModel ? state.electronicControlModel : '--'}</div>
            </div>
          </div>
          <div className={s.infoRow}>
            <div className={s.infoItem}>
              <div className={s.infoKey}>其他:</div>
              <div className={s.infoValue}>{state.remark ? state.remark : '--'}</div>
            </div>
          </div>
        </DetailBlock>
        <DetailBlock
          title="信息补充"
        >
          <div className={s.content}>
            {state.content}
          </div>
        </DetailBlock>
        <DetailBottomPlaceholder />
      </div>
      <ConfirmDetailButton
        onClick={() => {
          callUp(state.phone)
        }}
      >
        联系我们
      </ConfirmDetailButton>
      />
    </React.Fragment>
  )
})