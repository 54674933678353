import React from 'react'
import s from './form-set.module.scss'

export default function FormSet(props) {
  return (
    <div>
      <div className={s.title}>{props.title}</div>
      <div className={s.content}>
        {props.children}
      </div>
    </div>
  )
}