import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";

dayjs.locale("zh-cn");
dayjs.extend(relativeTime);

export function parseServerDateTime(str) {
  return dayjs(str, "YYYY-MM-DD HH:mm:ss").toDate();
}

export function relativeFromNow(str) {
  return dayjs(str, "YYYY-MM-DD HH:mm:ss").fromNow();
}

export function parseServerDateAsYYYYMMDD(str) {
  return dayjs(str, "YYYY-MM-DD HH:mm:ss").format("YYYY/MM/DD");
}

export function parseServerDateByTimestamp(timestamp) {
  return dayjs(timestamp).format("YYYY/MM");
}
