import React from "react";
import s from "./TechnologyExchange.module.scss";
import c from "./common.module.scss";

import SimilarSearch from "../../components/SimilarSearch";
import TabsWithMenu from "../../components/TabsWithMenu";
import TabsListView from "../../components/TabsListView";
import Toggle from "../../components/Toggle";
import Searcth from "../Search";
import native from "../../utils/native";
import FixedButton from "../../components/FixedButton";

import playBtn2x from "../../images/PlayVideoBtn@2x.png";
import playBtn3x from "../../images/PlayVideoBtn@3x.png";
import comment2x from "../../images/comment@2x.png";
import comment3x from "../../images/comment@3x.png";

import { fetchTabCategory, fetchTechnologyExchangeList, setIsCollection, setIsLiked } from "../../server-api/tabs";
import { parseServerDateAsYYYYMMDD } from "../../utils/date";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职
export default function Main() {

  const [tabs, setTabs] = React.useState([]);
  const [current, setCurrent] = React.useState(0);
  const [loadingCategory, setLoadingCategory] = React.useState(true)

  React.useEffect(() => {
    document.title = "普通交流";

    fetchTabCategory(1)
    .then(res => {
      if (res.data.code === 1000) {
        const origin = res.data.data.list;
        setTabs((Array.isArray(origin) ? origin : []).map((e, index) => ({
          key: e.id,
          title: e.name,
          index: index
        })));
        setLoadingCategory(false)
      }
    });
  }, []);

  const handleGotoDetail = (id) => {
    native.openWebview("/techdetail", {id});
  };

  const handleLike = async (obj, success) => {
    const res = await setIsLiked({
      objectId: obj.id,
      objectType: 1,
      updateType: obj.status
    });
    if (res.data.code === 1000) {
      success();
    }
  };

  const handleCollection = async (obj, success) => {
    const res = await setIsCollection({
      objectId: obj.id,
      objectType: 1,
      updateType: obj.status
    });
    if (res.data.code === 1000) {
      success();
    }
  };

  const renderListRow = (record, key) => {

    return <div key={key} className={s.listItem}>
      <div className={s.top}>
        <img src={record.avatar} alt=""/>
        <div className={s.person} style={{
          flexFlow: record.userCompany ? "column" : "unset"
        }}>
          <div className={s.basic}>
            <div className={s.basicTitle}>
              <span className={s.name}>{record.name}</span>
              <span className={s.level}>{record.levelName}</span>
            </div>
            <div className={s.date}>{parseServerDateAsYYYYMMDD(record.createAt)}</div>
          </div>
          {
            record.userCompany && <div className={s.compony}>{record.userCompany}</div>
          }
        </div>
      </div>
      <div className={s.center} onClick={() => {
        handleGotoDetail(record.id);
      }}>
        <div className={s.content}>{record.content}</div>
        <div className={s.imgUrls}>
          {
            record.uploadFiles.map((item, i) => {
              return <div
                className={s.carouse}
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.fileType == 2) {
                    native.previewImage(i, record.uploadFiles.map(s => s.url));
                  }
                  if (item.fileType == 3) {
                    native.playVideo(item.videoUrl);
                  }
                }}
              >
                <img src={item.thumbnail} alt=""/>
                {
                  item.fileType == 3 && <div
                    className={s.playMask}
                  >
                    <img
                      className={s.playBtn}
                      src={playBtn2x}
                      srcSet={`${playBtn2x} 2x, ${playBtn3x} 3x`}
                      alt=""
                    />
                  </div>
                }
              </div>;
            })
          }
        </div>
      </div>
      <div className={s.footer}>
        <Toggle
          id={record.id}
          num={record.likeCount}
          status={record.likeStatus}
          async={handleLike}
        />
        <div
          className={s.iconWithText}
          onClick={() => {
            native.openWebview("/techdetail", {
              id: record.id,
              scrollToComment: true,
            });
          }}
        >
          <img
            src={comment2x}
            srcSet={`${comment2x} 2x, ${comment3x} 3x`}
            alt=""
          />
          <span>{record.commentCount}</span>
        </div>
        <Toggle
          type={"collect"}
          id={record.id}
          num={record.collectionCount}
          status={record.collectionStatus}
          async={handleCollection}
        />
      </div>
    </div>;
  };

  const [searchKey, setSearchKey] = React.useState("");
  const [showSearch, setShowSearch] = React.useState("none");

  const handleSearchOn = () => {
    setShowSearch("block");
  };

  const handleSearchOff = () => {
    setShowSearch("none");
  };

  const handleReceiveSearchKey = (x) => {
    setSearchKey(x);
  };

  if (loadingCategory) {
    return null
  }

  return <div className={c.page}>
    <div className={c.pageTop}>
      <SimilarSearch
        click={handleSearchOn}
        placeholder={"请根据您的需求输入关键词"}
        value={searchKey}
      />
    </div>
    <div className={c.pageTabs}>
      <TabsWithMenu
        tabs={tabs}
        active={current}
        setTab={setCurrent}
      />
    </div>
    <div className={c.pageContent}>
      <TabsListView
        fetchFunc={fetchTechnologyExchangeList}
        renderRow={renderListRow}
        subTabKey={current}
        searchKey={searchKey}
        categoryID={tabs[current].key}
      />
    </div>
    <FixedButton text={"我要交流"} click={() => {
      native.openWebview("/pubtech", {});
    }}/>
    <Searcth
      show={showSearch}
      onBack={handleSearchOff}
      receiveSearchKey={handleReceiveSearchKey}
    />
  </div>;
}
