import qs from 'query-string'
import store from '../mobx/store'

let nativeApi = window.wx.miniProgram
if (window.native) {
  nativeApi = {
    ...window.native,
  }
}
nativeApi.relogin = () => {
  console.log('打开重新登陆界面')
  if (window.native) {
    window.native.relogin()
  } else {
    window.wx.miniProgram.reLaunch({
      url: '/pages/index'
    })
  }
}
nativeApi.playVideo = (videoSrc) => { 
  console.log('播放视频')
  if (window.native) {
    window.native.playVideo(videoSrc)
  } else {
    window.wx.miniProgram.navigateTo({
      url: `/pages/play-video?src=${encodeURIComponent(videoSrc)}`
    })
  }
}
nativeApi.previewImage = (currentIndex, images) => { 
  if (window.native) {
    window.native.previewImage(currentIndex, images)
  } else {
    window.wx.previewImage({
      current: images[currentIndex],
      urls: images,
    })
  }
}
nativeApi.openWebview = (pageUrl, query) => {
  const l = window.location
  const q = {
    ...query,
    token: store.user.token,
  }
  let url = `${l.protocol}//${l.host}/#${pageUrl}?${qs.stringify(q)}`
  if (process.env.NODE_ENV === 'development') {
    window.location.href = url
  } else if (window.native) {
    window.native.openWebview(url)
  } else {
    window.wx.miniProgram.navigateTo({
      url: `/pages/webview?src=${encodeURIComponent(url)}`
    })
  }
}
window.op = nativeApi.openWebview
export default nativeApi