import store from '../mobx/store'
import axios from 'axios'
import { BASE_URL } from '../constants/server'
import native from '../utils/native'

export const serverInst = axios.create({
  baseURL: BASE_URL,
  method: 'post',
  timeout: 12000,
  headers: {
    'Authorization': store.user.token,
  },
})

serverInst.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.data) {
    if (response.data.code === 1006) {
      console.log(response.data)
      native.relogin()
    }
    if (response.data.code !== 1000) {
      return Promise.reject(response.data)
    }
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
