import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import s from './Invitation.module.scss'
import logo2x from '../images/invitation/logo@2x.png'
import logo3x from '../images/invitation/logo@3x.png'
import account2x from '../images/invitation/account@2x.png'
import account3x from '../images/invitation/account@3x.png'
import password2x from '../images/invitation/password@2x.png'
import password3x from '../images/invitation/password@3x.png'
import captcha2x from '../images/invitation/captcha@2x.png'
import captcha3x from '../images/invitation/captcha@3x.png'
import { useLocalStore, observer } from 'mobx-react-lite'
import { sendCaptcha, registerAccount } from '../server-api/user'
import { Toast } from 'antd-mobile'
import { action } from 'mobx'

export default observer(function Invitation() {
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const { code } = query;
  const state = useLocalStore(() => ({
    account: '',
    password: '',
    captcha: '',
    countdown: 0,
    timer: null,
  }))

  return (
    <div className={s.page}>
      <div>
        <div className={s.title}>
          <img className={s.logo} src={logo2x} srcSet={`${logo2x} 2x, ${logo3x} 3x`} alt=""/>
          <div className={s.slogan}>纺机人自己的平台</div>
        </div>
        <div className={s.form}>
          <div className={s.row}>
            <span className={s.codeLabel}>邀请码</span>
            <span className={s.code}>{code}</span>
          </div>
          <div className={s.row}>
            <img className={s.accountIcon} src={account2x} srcSet={`${account2x} 2x, ${account3x} 3x`} alt=""/>
            <div className={s.sep} />
            <input value={state.account} onChange={e => {
              state.account = e.target.value
            }} autoComplete="off" type="text" className={s.input} placeholder="请输入您的手机号码" />
          </div>
          <div className={s.row}>
            <img className={s.passwordIcon} src={password2x} srcSet={`${password2x} 2x, ${password3x} 3x`} alt=""/>
            <div className={s.sep} />
            <input value={state.password} onChange={e => {
              state.password = e.target.value
            }} autoComplete="new-password" type="password" className={s.input} placeholder="请输入6-18位密码" />
          </div>
          <div className={s.row}>
            <img className={s.captchaIcon} src={captcha2x} srcSet={`${captcha2x} 2x, ${captcha3x} 3x`} alt=""/>
            <div className={s.sep} />
            <input value={state.captcha} onChange={e => {
              state.captcha = e.target.value
            }} className={`${s.input} ${s.captcha}`} placeholder="请输入验证码" />
            <div
              value={state.captcha}
              className={s.sendCaptcha}
              onClick={action(() => {
                if (state.countdown <= 0) {
                  clearInterval(state.timer)
                  sendCaptcha(state.account)
                    .then(() => {
                      state.countdown = 60;
                      state.timer = setInterval(() => {
                        state.countdown -= 1
                        if (state.countdown <= 0) {
                          clearInterval(state.timer)
                        }
                      }, 1000)
                    })
                    .catch((e) => {
                      Toast.info(e.message, 2, null, false)
                    })
                }
              })}
            >{state.countdown <= 0 ? '发送验证码' : `${state.countdown}s`}</div>
          </div>
          <div className={s.submit} onClick={() => {
            registerAccount({
              code: state.captcha,
              invitationCode: code,
              password: state.password,
              phone: state.account,
            })
            .then(() => {
              Toast.info('注册成功', 2, () => {
                history.push('/openapp')
              }, false)
            })
            .catch((e) => {
              Toast.info(e.message, 2, null, false)
            })
          }}>确认注册</div>
        </div>
      </div>
    </div>
  )
})