import React from "react";
import del2x from "../images/del@2x.png";
import del3x from "../images/del@3x.png";
import back2x from "../images/back@2x.png";
import back3x from "../images/back@3x.png";

import "./Search.scss";

import { SearchBar } from "antd-mobile";
import { serverInst } from "../server-api/request";

const getHot = () => {
  return serverInst.post("/api/product/hotSearch");
};

const key = "q_machine_store_forum";

const max = 10;

const getHistory = () => {
  const keyWords = window.localStorage.getItem(key);
  return keyWords ? keyWords.split(",") : [];
};

export default function Seach(props) {
  const [searchParam, setSearchParam] = React.useState("");

  function onSearchChange(x) {
    setSearchParam(x);
  }

  const [hots, setHots] = React.useState([]);

  React.useEffect(() => {
    getHot().then(res => {
      if (res.data.code === 1000) {
        setHots(res.data.data);
      }
    });
  }, []);

  const [localHistory, setLocalHistory] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  function handleOnSearch(x) {
    props.receiveSearchKey(x);
    props.onBack();

    if (x) {
      addToHistory(x);
      setRefresh(!refresh);
    }
  }

  function handleDelete() {
    window.localStorage.setItem(key, "");
    setRefresh(!refresh);
  }

  function addToHistory(word) {
    let keyWords = getHistory();

    if (keyWords.length) {
      let index = keyWords.indexOf(word);
      if (index == -1) {
        let length = keyWords.length;
        if (length >= max) {
          keyWords.pop(word);
        }
        keyWords.unshift(word);
      }
      window.localStorage.setItem(key, keyWords.join(","));
    } else {
      keyWords = word;
      window.localStorage.setItem(key, keyWords);
    }
  }

  React.useEffect(() => {
    setLocalHistory(getHistory());
  }, [refresh]);


  function handleClickTag(e) {
    const value = e.target.getAttribute("value");
    if (value) {
      setSearchParam(value);
      handleOnSearch(value);
    }
  }

  return <div className={"searchWeapper"} style={{
    display: props.show
  }}>
    <div className={"header"}>
      <div className={"back"} onClick={props.onBack}>
        <img
          src={back2x}
          srcSet={`${back2x} 2x, ${back3x} 3x`}
          alt=""
        />
      </div>
      <SearchBar
        value={searchParam}
        placeholder="请输入关键字"
        onSubmit={handleOnSearch}
        onClear={onSearchChange}
        onCancel={handleOnSearch}
        showCancelButton
        cancelText={"搜索"}
        onChange={onSearchChange}
      />
    </div>
    <div className={"content"}>
      <div className={"history"}>
        {
          localHistory.length ? <React.Fragment>
            <div className={"title"}>
              <div className={"label"}>搜索历史</div>
              <img
                onClick={handleDelete}
                src={del2x}
                srcSet={`${del2x} 2x, ${del3x} 3x`}
                alt=""
              />
            </div>
            <div className={"keyWords"} onClick={handleClickTag}>
              {
                localHistory.map(e => {
                  return <span key={e} value={e}>{e}</span>;
                })
              }
            </div>
          </React.Fragment> : null
        }
        <div className={"title"}>
          <div className={"label"}>大家都在搜</div>
        </div>
        <div className={"keyWords"} onClick={handleClickTag}>
          {
            hots.map(e => {
              return <span key={e} className={"hot"} value={e}>{e}</span>;
            })
          }
        </div>
      </div>
    </div>
  </div>;
}
