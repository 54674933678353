import React from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import s from './TechComment.module.scss'
import range from '../utils/range'
import closeBtn2x from '../images/close-comment-btn@2x.png'
import closeBtn3x from '../images/close-comment-btn@2x.png'
import CommentInputBox from './CommentInputBox'

function Comment(props) {
  const children = props.comment.childCommentVOList
  const MORE_COUNT = Math.min(2, children.length)
  return (
    <div className={classNames(s.comment, {
      [s.mainBorderBottom]: props.showBorderBottom,
    })}>
      <img className={s.avatar} src={props.comment.image} alt="" />
      <div className={s.commentMain}>
        <div className={s.header}>
          <div className={`${s.commentUser} ${s.autoHiddenText}`}>{props.comment.nick}</div>
          <div className={s.commentlevelName}>{props.comment.levelName}</div>
        </div>
        <div className={s.commentContent}>
          {props.comment.content}
        </div>
        {
          !props.hideReplyBtn &&
            <button
              className={s.replyBtn}
              onClick={props.onReply}
            >
              回复
            </button>
        }
        {
          children.length > 0 && !props.hideMore &&
          <div
            className={s.someMore}
            onClick={props.onShowDetail}
          >
            {
              range(0, MORE_COUNT).map((i) =>
                <div key={children[i].id}>
                  {children[i].nick}
                  <span className={s.smallContent}>{children[i].content}</span>
                </div>
              )
            }
            <div>
              共{children.length}条回复
            </div>
          </div>
        }
        {
          children.length > 0 && props.showDetailMore &&
            children.map((child, i) =>
              <Comment
                key={child.id}
                hideReplyBtn
                showBorderBottom={ i !== children.length - 1}
                comment={child}
              />
            )
        }
      </div>
    </div>
  )
}

export default function TechComment(props) {
  const children = props.comment.childCommentVOList
  const $input = React.useRef(null)
  const [showMore, setShowMore] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [commentingId, setCommentingId] = React.useState(props.comment.id)

  return (
    <React.Fragment>
      <Comment
        comment={props.comment}
        onReply={props.onReply}
        onShowDetail={() => {
          setShowMore(true)
        }}
      />
      {
        <CSSTransition
          in={showMore}
          unmountOnExit
          timeout={200}
        >
          <div className={classNames(s.modalBg, 'TechComment_modelBg')} onClick={() => {
            setShowMore(false)
          }}>
            <div className={classNames(s.window, 'TechComment_window')}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div className={s.parentComment}>
                <div className={s.windowTitle}>
                  <div
                    className={s.closeC}
                    onClick={() => {
                      setShowMore(false)
                    }}
                  >
                    <img className={s.closeBtn} src={closeBtn2x} srcSet={`${closeBtn2x} 2x, ${closeBtn3x} 3x`} alt="" />
                  </div>
                  {children.length}条回复
                </div>
                <div className={s.secondLevelComment}>
                  <Comment
                    hideMore
                    hideReplyBtn
                    comment={props.comment}
                  />
                </div>
              </div>
              <div className={s.myComments}>
                {
                  children.map(child =>
                    <Comment
                      key={child.id}
                      hideMore
                      showDetailMore
                      comment={child}
                      onReply={() => {
                        $input.current.focus()
                        setCommentingId(child.id)
                      }}
                    />
                  )
                }
              </div>
              <CommentInputBox
                ref={$input}
                value={inputValue}
                onClick={() => {
                  // 点击输入框重置评论目标为当前文章
                  setCommentingId(props.comment.id)
                  $input.current.focus()
                }}
                onChange={(e) => {
                  setInputValue(e.currentTarget.value)
                }}
                onSend={() => {
                  props.onReplySubComment &&
                  props.onReplySubComment(inputValue, commentingId, () => {
                    setInputValue('')
                    setShowMore()
                  })
                }}
              />
            </div>
          </div>
        </CSSTransition>
      }
    </React.Fragment>
  )
}