import React from "react";
import s from "./SimilarSearch.module.scss";

import search2x from "../images/search@2x.png";
import search3x from "../images/search@3x.png";

export default function SimilarSearch(props) {
  const {
    placeholder,
    click,
    value
  } = props;

  return <div className={s.similarSearchWrapper}>
    <div className={s.search} onClick={props.click}>
      <img
        className={s.icon}
        src={search2x}
        srcSet={`${search2x} 2x, ${search3x} 3x`}
        alt=""
      />
      <span className={s.placeholder}>{value || placeholder}</span>
    </div>
  </div>;
}
