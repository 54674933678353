import React from 'react'
import s from './DetailBlock.module.scss'
import { HeaderIndicator } from './HeaderIndicator'

export default function DetailBlock(props) {
  return (
    <div className={s.detailBlock}>
      <div className={s.header}>
        <HeaderIndicator />
        <div className={s.title}>{props.title}</div>
      </div>
      <div className={s.content}>
        {props.children}
      </div>
    </div>
  )
}