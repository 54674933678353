import React from "react";
import { Tabs, Popover } from "antd-mobile";
import more2x from "../images/more@2x.png";
import more3x from "../images/more@3x.png";
import close2x from "../images/close@2x.png";
import close3x from "../images/close@3x.png";
import "./TabsWithMenu.scss";

export default function TabsWithMenu(props) {
  const {
    tabs,
    setTab,
    active
  } = props;

  const [showMore, setShowMore] = React.useState(false);

  function handleShowMore() {
    setShowMore(true);
  }

  function handleHiddenMore() {
    setShowMore(false);
  }

  function handleTabClick(tab) {
    handleHiddenMore();
    setTab(tab.index);
  }

  const overlay = React.useMemo(() => {
    return (
      <div className={"subTabs"}>
        <div className={"close"}>
          <img
            src={close2x}
            onClick={handleHiddenMore}
            srcSet={`${close2x} 2x, ${close3x} 3x`}
            alt=""
          />
        </div>
        <div className={"tabs"}>
          {
            tabs.map(e => {
              return <span onClick={() => {
                handleTabClick(e);
              }} key={e.key} className={`${e.index == active ? "active" : ""}`}>{e.title}</span>;
            })
          }
        </div>
      </div>
    );
  }, [active, tabs]);

  return <div className={"tabsWithMenuWrapper"}>
    <div className={"tabsWithMenu"}>
      <Tabs
        tabs={tabs}
        onTabClick={handleTabClick}
        renderTabBar={props => <Tabs.DefaultTabBar page={4} {...props} activeTab={active}/>}
      />
    </div>
    {
      tabs.length > 4 && <Popover
        overlayClassName={"tabsWithMenuPopover"}
        visible={showMore}
        overlay={overlay}
        onVisibleChange={setShowMore}
      >
        <div className={"tabsWithMenuIcon"}>
          <img
            onClick={handleShowMore}
            className={"more"}
            src={more2x}
            srcSet={`${more2x} 2x, ${more3x} 3x`}
            alt=""
          />
        </div>
      </Popover>
    }
  </div>;
}
