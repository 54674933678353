import { serverInst } from "./request"

function regionsList2Tree(all, currentList = [], pid = 0, n = 0) {
  if (n > 2) {
    return
  } else {
    const list = all.filter(item => item.pid === pid)
    for (let i = 0, len = list.length; i < len; ++i) {
      const item = {
        value: list[i].id,
        label: list[i].name,
        children: [],
      }
      currentList.push(item)
      regionsList2Tree(all, item.children, item.value, n + 1)
    }
  }
}
export function fetchRegionsOfChina() {
  return serverInst.post('/api/area/list')
    .then(res => {
      const d = res.data.data.list
      const regions = []
      regionsList2Tree(d, regions)
      return {
        ...res.data,
        data: regions,
      }
    })
}