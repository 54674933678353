import React from 'react'
import userStore from './user-store'// 6.x or mobx-react-lite@1.4.0

const store = {
  user: userStore,
}

export default store

const storeContext = React.createContext(store)

export const StoreProvider = ({ children }) => {
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  return store
}

export function useUserStore() {
  return useStore().user
}