import React from "react";
import c from "./common.module.scss";
import s from "./ApplyForJob.module.scss";

import SimilarSearch from "../../components/SimilarSearch";
import TabsListView from "../../components/TabsListView";
import FixedButton from "../../components/FixedButton";

import { fetchApplyForJobList } from "../../server-api/tabs";
import { parseServerDateAsYYYYMMDD } from "../../utils/date";
import { callUp } from "../../utils/capacity";

import native from "../../utils/native";
import Searcth from "../Search";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职
export default function Main() {
  // const [tabs, setTabs] = React.useState([]);
  // const [current, setCurrent] = React.useState(0);

  React.useEffect(() => {
    document.title = "求职";
  //   fetchTabCategory(5)
  //   .then(res => {
  //     if (res.data.code === 1000) {
  //       const origin = res.data.data.list;
  //       setTabs((Array.isArray(origin) ? origin : []).map((e, index) => ({
  //         key: e.id,
  //         title: e.name,
  //         index: index
  //       })));
  //     }
  //   });
  }, []);

  const handleGoTo = () => {
    native.openWebview("/pubjobdemand", {});
  };

  const handleGotoDetail = (id) => {
    native.openWebview("/jobdemanddetail", {id});
  };

  const handleCellPhone = (e, phone) => {
    e.stopPropagation();
    callUp(phone);
  };

  const renderListRow = (record, key) => {
    return <div key={key} className={s.listItem} onClick={() => {
      handleGotoDetail(record.id);
    }}>
      <div className={s.top}>
        <div className={s.person}>
          <img src={record.avatar} alt=""/>
          <div className={s.basic}>
            <div>
              <span className={s.name}>{record.name}</span>
              <span className={s.level}>{record.levelName}</span>
            </div>
            <div className={s.age}>
              <span>{record.age}</span>岁
              <span>{record.workExperience}</span>
            </div>
          </div>
        </div>
        <div className={s.date}>
          {parseServerDateAsYYYYMMDD(record.createAt)}
        </div>
      </div>
      <div className={s.center}>
        {record.title}
      </div>
      <div className={s.footer}>
        <div className={s.salary}>期望薪资: {record.expectedSalary ? record.expectedSalary : '面议'}</div>
        {
          record.phone && <div className={c.listItemOperate} onClick={(e) => {
            handleCellPhone(e, record.phone);
          }}>联系Ta</div>
        }
      </div>
    </div>;
  };

  const [searchKey, setSearchKey] = React.useState("");
  const [showSearch, setShowSearch] = React.useState("none");

  const handleSearchOn = () => {
    setShowSearch("block");
  };

  const handleSearchOff = () => {
    setShowSearch("none");
  };

  const handleReceiveSearchKey = (x) => {
    setSearchKey(x);
  };

  return <div className={c.page}>
    <div className={c.pageTop}>
      <SimilarSearch
        click={handleSearchOn}
        placeholder={"请根据您的需求输入关键词"}
        value={searchKey}
      />
    </div>
    <div className={s.pageContent}>
      <TabsListView
        fetchFunc={fetchApplyForJobList}
        renderRow={renderListRow}
        subTabKey={0}
        searchKey={searchKey}
        categoryID={0}
      />
    </div>
    <FixedButton text={"我要求职"} click={handleGoTo}/>
    <Searcth
      show={showSearch}
      onBack={handleSearchOff}
      receiveSearchKey={handleReceiveSearchKey}
    />
  </div>;
}
