import React from 'react'
import s from './submit-publish.module.scss'

export default function SubmitPublish(props) {
  return (
    <div className={s.bg}>
      <div onClick={props.onClick} className={s.btn}>
        {props.children}
      </div>
    </div>
  )
}