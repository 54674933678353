import React from 'react'
import s from './DetailContact.module.scss'
import { HeaderIndicator } from './HeaderIndicator'

export function DetailContact(props) {
  return (
    <div className={s.detailContact}>
      <HeaderIndicator />
      <table className={s.table}>
        <tbody>
          {
            props.entries.map((item, i) =>
              <tr key={i}>
                <td className={s.key}>{item.key}</td>
                <td>
                  {
                    item.render ? item.render(item.value) : item.value
                  }
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )
}