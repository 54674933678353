import React from 'react'
import { action } from 'mobx'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Toast, Flex } from 'antd-mobile'
import qs from 'query-string'
import dayjs from 'dayjs'
import s from './JobDetail.module.scss'
import ConfirmDetailButton from '../components/ConfirmDetailButton'
import { fetchRegionsOfChina } from '../server-api/common';
import { fetchJobDetail, changeCollectedStatus } from '../server-api/forum';
import { useLocation } from 'react-router-dom';
import { DetailContact } from '../components/DetailContact';
import { findRegionString } from '../utils/region';
import { hidePhoneNumber } from '../utils/formatter';
import { parseServerDateTime } from '../utils/date';
import { callUp } from '../utils/capacity';
import DetailRUActions from '../components/DetailRUActions';
import native from '../utils/native'

export default observer(function JobDemandDetail(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const state = useLocalStore(() => {
    return {
      loading: true,
      title: '',
      subtitle: '',
      view: 0,
      createdAt: new Date(),
      phone: '',
      hidePersonal: true,
      region: '',
      content: '',
      collected: false,
    }
  })
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '招聘'
    fetchRegionsOfChina()
      .then(r => {
        if (r.code === 1000) {
          setRegions(r.data)
        }

        fetchJobDetail(id)
          .then(action(res => {
            console.log(res)
            const data = res.data.data

            state.loading = false
            state.phone = data.phone
            state.hidePersonal = data.hidePhone === 1
            state.region = findRegionString(r.data, data.home.split(',').map(i => parseInt(i)), 2)
            state.createdAt = parseServerDateTime(data.createAt)
            state.avatar = data.avatar
            state.username = data.name
            state.levelName = data.levelName
            state.expectedSalary = data.expectedSalary
            state.createdAt = parseServerDateTime(data.createAt)
            state.jobName = data.title
            state.company = data.company
            state.financingStage = data.financingStage
            state.skill = data.skillRequirement
            state.jobDetail = data.jobDetails
            state.team = data.remark
            state.location = data.location
            // 收藏状态 1:未收藏 2:已收藏
            state.collected = data.collectionStatus === 2
          }))
          .catch(e => Toast.info(e.message, 2, null, false))
      })
      .catch(e => Toast.info(e.message, 2, null, false))
  }, [])
  
  if (state.loading) return null
  return (
    <React.Fragment>
      <div className={s.page}>
        <div className={s.header}>
          <div className={s.headerContent}>
            <div className={s.jobName}>{state.jobName}</div>
            <div className={s.personlabel}>
              {
                `${state.region} | ${state.company} | ${state.financingStage}`
              }
            </div>
            <div className={s.headerFoot}>
              <div className={s.expectedSalary}>
                薪酬待遇: {state.expectedSalary || '面议'}
              </div>
              <div className={s.createdAt}>
                {dayjs(state.createdAt).format('YYYY/MM/DD')}
              </div>
            </div>
          </div>
          <DetailRUActions
            collected={state.collected}
            onCollect={() => {
              console.log('x')
              changeCollectedStatus(4, id, true)
                .then(() => {
                  state.collected = true
                })
            }}
            onShare={() => {

            }}
            onCancelCollect={() => {
              changeCollectedStatus(4, id, false)
                .then(() => {
                  state.collected = false
                })
            }}
          />
        </div>
        <div className={s.person}>
          <div className={s.avatarC}>
            <img className={s.avatar} src={state.avatar} alt="" />
          </div>
          <Flex>
            <div className={s.userName}>{state.username}</div>
            <div className={s.levelName}>{state.levelName}</div>
          </Flex>
        </div>
        <div className={s.borderBottom}>
          <div />
        </div>
        <DetailContact
          entries={[
            {
              key: '本人电话',
              value: state.phone,
              render(v) {
                const n = state.hidePersonal ? hidePhoneNumber(v) : v
                return <span className={s.phoneNumber}>{n}</span>
              },
            },
            {
              key: '工作地址',
              value: state.location || '未填写工作地址',
            }
          ]}
        />
        <div className={s.mainContent}>
          <div className={s.block}>
            <div className={s.blockHeader}>职位详情</div>
            <div className={s.blockContent}>{state.jobDetail || '未填写任何数据'}</div>
          </div>
          <div className={s.block}>
            <div className={s.blockHeader}>技能要求</div>
            <div className={s.blockContent}>{state.skill || '未填写任何数据'}</div>
          </div>
          <div className={s.block}>
            <div className={s.blockHeader}>团队介绍</div>
            <div className={s.blockContent}>{state.team || '未填写任何数据'}</div>
          </div>
        </div>
      </div>
      <ConfirmDetailButton
        onClick={() => {
          callUp(state.phone)
        }}
      >
        联系我们
      </ConfirmDetailButton>
    </React.Fragment>
  )
})