import React from 'react'
import s from './OpenApp.module.scss'
import miniqrcode from '../../images/OpenApp/miniqrcode.jpeg'

export default function OpenApp() {
  return (
    <div className={s.page}>
      <img src={miniqrcode} className={s.miniqrcode} alt="" />
      <div className={s.tips}>
        长按保存小程序码<br />
        打开微信扫码即可
      </div>
    </div>
  )
}
