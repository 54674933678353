import { observable, action } from "mobx"
import anyQs from 'any-qs'

const urlQuery = anyQs(window.location.href)

export default observable({
  token: urlQuery.token,
  updateToken: action(function(v) {
    this.token = v
  }),
})