import React from 'react'
import classNames from 'classnames'
import { Picker, DatePicker } from 'antd-mobile'
import { Toast } from 'antd-mobile'
import qs from 'query-string'
import shortid from 'shortid'
import { useLocation } from 'react-router-dom'
import { createForm } from 'rc-form'
import FormPickerChild from '../components/form-picker-child'
import FormRow from '../components/form-row'
import s from './PubSupply.module.scss'
import UploadImages from '../components/upload-images';
import SubmitPublish from '../components/submit-publish';
import native from '../utils/native'
import { fetchCategory, submitPubSupply, fetchSupplyDetail, updateSupply } from '../server-api/forum';
import FormSet from '../components/form-set';
import FormBooleanSeletor from '../components/form-boolean-selector';
import { fetchRegionsOfChina } from '../server-api/common';

export default createForm()(function PubSupply(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const { getFieldProps, getFieldValue } = props.form
  const [categories, setCategories] = React.useState([])
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '供给发布'
  }, [])
  React.useEffect(() => {
    Promise.all([
      fetchCategory(3)
        .then(res => {
          if (res.data.code === 1000) {
            setCategories(res.data.data.list.map(item => ({
              value: item.id,
              label: item.name,
            })))
          }
        }),
      fetchRegionsOfChina()
        .then(res => {
          if (res.code === 1000) {
            setRegions(res.data)
          }
        })
      ])
      .then(() => {
        if (id) {
          fetchSupplyDetail(id)
            .then(res => {
              const d = res.data.data
              console.log(d)
              const uploadFiles = d.uploadFiles.map(up => ({
                ossURL: up.url,
                progress: 100,
                uploaded: true,
                localurl: up.thumbnail,
                name: '',
                id: shortid.generate(),
                isVideo: up.fileType === 3,
                failed: false,
                file: null,
              }))
              if (uploadFiles.length > 0 && uploadFiles[0].isVideo) {
                const video = uploadFiles[0]
                props.form.setFieldsValue({
                  // category: 
                  media: {
                    video,
                    images: [],
                  }
                })
              } else {
                props.form.setFieldsValue({
                  media: {
                    video: null,
                    images: uploadFiles
                      .filter(up => up.fileType !== 3),
                  }
                })
              }
              props.form.setFieldsValue({
                category: [''+d.categoryId],
                content: d.content,
                title: d.title,
                price: d.expectedSalary,
                address: d.location,
                phone: d.phone,
                hidePersonal: d.hidePhone === 1,
                brand: d.brandName,
                model: d.model,
                spec: d.specification,
                amount: d.number,
                produtionDate: d.productionTime ? new Date(d.productionTime) : undefined,
                region: d.home ? d.home.split(',').map(n => parseInt(n)) : undefined,
                deviceStatus: d.equipmentStatus === '0' ? '0' : '1',
                duobiModel: d.multiArmModel,
                zongkuangAmount: d.healdFramesNumber,
                jingyoubi: d.warpBeamRatio,
                juankunbi: d.rollerRollRatio,
                diankongModel: d.electronicControlModel,
                other: d.remark,
              })
            })
        }
      })
      .catch(err => {
        Toast.info(err.message, 2, null, false)
      })
  }, [])

  return (
    <div className={s.page}>
      <Picker
        data={categories}
        cols={1}
        {...getFieldProps('category', {
          rules: [
            {
              required: true,
              message: '请选择所供给产品分类',
            }
          ],
        })}
      >
        <FormPickerChild
          title="产品分类"
          placeholder="请选择所供给产品的类别"
          required
          value={getFieldValue('category')}
        />
      </Picker>
      <UploadImages
        {...getFieldProps('media', {
          rules: [
            {
              validator(rule,value,callback){
                if (!value) {
                  return new Error('请上传图片')
                }
                if (value.video) {
                  if (value.video.failed) {
                    return new Error('视频上传失败')
                  }
                  if (!value.video.uploaded) {
                    return new Error('请等待上传完成')
                  }
                } else {
                  for (let i = 0, len = value.images.length; i < len; ++i) {
                    if (value.images[i].failed) {
                      return new Error('图片上传失败')
                    }
                    if (!value.images[i].uploaded) {
                      return new Error('请等待上传完成')
                    }
                  }
                }
                return true
              }
            }
          ]
        })}
      />
      <div className={s.sep}>
        <FormRow
          title="产品标题"
          required
          showBorder
          inputProps={{
            placeholder: "请输入产品的标题描述",
            ...getFieldProps('title', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '请输入产品标题',
                }
              ],
            })
          }}
          >
        </FormRow>
        <FormRow
          title="转让价格"
          // required
          inputProps={{
            placeholder: "请输入产品的转让价格",
            type: "number",
            ...getFieldProps('price', {
              initialValue: '',
              rules: [
                // {
                //   type: 'number',
                //   transform(value) {
                //     return parseFloat(value) || 0
                //   },
                //   message: '价格只能输入数字',
                // }
              ],
            })
          }}
        >
        </FormRow>
      </div>
      <FormSet
        title="联系方式"
      >
        <FormRow
          title="地址"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入您的具体地址",
            ...getFieldProps('address', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入产品的具体地址',
                // }
              ],
            })
          }}
        >
        </FormRow>
        <FormRow
          title="电话"
          required
          inputProps={{
            placeholder: "请输入您的联系电话",
            ...getFieldProps('phone', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '请输入您的联系电话',
                }
              ],
            })
          }}
        >
          <div className={s.isHidden}>
            <FormBooleanSeletor
              title="是否隐藏个人联系电话："
              {
                ...getFieldProps('hidePersonal', {
                  initialValue: true,
                })
              }
            />
          </div>
        </FormRow>
      </FormSet>
      <FormSet
        title="基本信息"
      >
        <FormRow
          title="品牌"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入设备品牌",
            ...getFieldProps('brand', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入设备品牌',
                // }
              ],
            })
          }}
        />
        <FormRow
          title="型号"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入设备型号",
            ...getFieldProps('model', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入设备型号',
                // }
              ],
            })
          }}
        />
        <FormRow
          title="规格"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入设备规格",
            ...getFieldProps('spec', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入设备规格',
                // }
              ],
            })
          }}
        />
        <FormRow
          title="数量"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入设备数量",
            type: 'number',
            ...getFieldProps('amount', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入设备数量',
                // },
                {
                  pattern: /^[0-9]*$/,
                  message: '设备数量必须为整数'
                }
              ],
            })
          }}
        />
        <DatePicker
          mode="date"
          minDate={new Date(2000, 1, 1, 0, 0, 0)}
          maxDate={new Date()}
          title="生产日期"
          {...getFieldProps('produtionDate', {
            rules: [
              // {
              //   required: true,
              //   message: '请选择设备生产日期',
              // }
            ],
          })}
        >
          <FormPickerChild
            title="生产日期"
            placeholder="请选择设备生产日期"
            // required
            showBorder
            value={getFieldValue('produtionDate')}
          />
        </DatePicker>
        <Picker
          data={regions}
          cols={3}
          title="请选择地区"
          {...getFieldProps('region', {
            rules: [
              // {
              //   required: true,
              //   message: '请选择地区',
              // }
            ],
          })}
        >
          <FormPickerChild
            title="所在地"
            placeholder="请选择设备所在地区"
            // required
            value={getFieldValue('region')}
          />
        </Picker>
      </FormSet>
      <FormSet
        title="设备信息"
      >
        <Picker
          data={[
            {
              value: '0',
              label: '停运',
            },
            {
              value: '1',
              label: '运行',
            },
          ]}
          cols={1}
          title="请选择设备状态"
          {...getFieldProps('deviceStatus', {
            initialValue: ['1'],
            rules: [
              {
                required: true,
                message: '请选择设备状态',
              }
            ],
          })}
        >
          <FormPickerChild
            title="设备状态"
            placeholder=""
            showBorder
            required
            value={getFieldValue('deviceStatus')}
          />
        </Picker>
        <FormRow
          title="多臂型号"
          showBorder
          inputProps={{
            placeholder: "请输入设备多臂型号",
            ...getFieldProps('duobiModel', {
              initialValue: '',
              rules: [
              ],
            })
          }}
        />
        <FormRow
          title="综框数量"
          showBorder
          inputProps={{
            placeholder: "请输入设备综框数量",
            type: 'number',
            ...getFieldProps('zongkuangAmount', {
              initialValue: '',
              rules: [
                {
                  pattern: /^[0-9]*$/,
                  message: '综框数量必须为整数'
                }
              ],
            })
          }}
        />
        <FormRow
          title="经轴比"
          showBorder
          inputProps={{
            placeholder: "请输入设备经轴比",
            ...getFieldProps('jingyoubi', {
              initialValue: '',
              rules: [
              ],
            })
          }}
        />
        <FormRow
          title="卷布辊比"
          showBorder
          inputProps={{
            placeholder: "请输入设备卷布辊比",
            ...getFieldProps('juankunbi', {
              initialValue: '',
              rules: [
              ],
            })
          }}
        />
        <FormRow
          title="电控型号"
          showBorder
          inputProps={{
            placeholder: "请输入设备电控型号",
            ...getFieldProps('diankongModel', {
              initialValue: '',
              rules: [
              ],
            })
          }}
        />
        <FormRow
          title="其它"
          inputProps={{
            placeholder: "请输入设备其它描述",
            ...getFieldProps('other', {
              initialValue: '',
              rules: [
              ],
            })
          }}
        />
      </FormSet>
      <div className={classNames(s.field, s.content)}>
        <div className={s.fieldTitle}><span style={{
          visibility: 'hidden',
        }}>*</span>信息补充</div>
        <div className={s.textAreaC}>
          <textarea
            className={s.textarea}
            placeholder="请输入您需要补充的产品信息"
            {...getFieldProps('content', {
              rules: [
                // {
                //   required: true,
                //   message: '请补充产品信息',
                // },
              ]
            })}
          >
          </textarea>
        </div>
      </div>
      <SubmitPublish
        onClick={() => {
          props.form.validateFields((error, value) => {
            if (error) {
              const firstKey = Object.keys(error)[0]
              Toast.info(error[firstKey].errors[0].message, 2, null, false)
              return
            }
            const params = {
              // 图片类型 1:无图片 2：图片 3：视频
              imageType: value.media.video ? 3 : 2,
              brandName: value.brand,
              categoryId: value.category[0],
              content: value.content,
              imageUrls: value.media.video ? [value.media.video.ossURL] : value.media.images.map(({ ossURL }) => ossURL),
              electronicControlModel: value.diankongModel,
              equipmentStatus: value.deviceStatus[0],
              expectedSalary: value.price,
              healdFramesNumber: value.zongkuangAmount,
              hidePhone: value.hidePersonal ? 1 : 2,
              home: value.region ? value.region.toString() : '',
              location: value.address,
              model: value.model,
              multiArmModel: value.duobiModel,
              number: value.amount,
              phone: value.phone,
              productionTime: value.produtionDate ? value.produtionDate.getTime() : '',
              remark: value.other,
              rollerRollRatio: value.juankunbi,
              specification: value.spec,
              title: value.title,
              warpBeamRatio: value.jingyoubi,
            }
            let api = submitPubSupply
            if (id) {
              params.id = id
              api = updateSupply
            }
            Toast.loading('正在发布', 0, null, true)
            api(params)
              .then(res => {
                Toast.hide()
                Toast.success('发布成功', 2, () => {
                  native.navigateBack()
                }, true)
              })
              .catch(e => {
                Toast.hide()
                if (e.code !== 1006) {
                  Toast.fail(e.message || '网络错误', 1.5, null, false)
                }
              })
          })
        }}
      >
        提交审核
      </SubmitPublish>
    </div>
  )
})