import React from 'react'
import classNames from 'classnames'
import { Picker } from 'antd-mobile'
import { Toast } from 'antd-mobile'
import { createForm } from 'rc-form'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import FormPickerChild from '../components/form-picker-child'
import FormRow from '../components/form-row'
import s from './PubJobDemand.module.scss'
import SubmitPublish from '../components/submit-publish';
import native from '../utils/native'
import { submitPubJobDemand, fetchJobDemandDetail, updateJobDemand } from '../server-api/forum';
import FormSet from '../components/form-set';
import FormBooleanSeletor from '../components/form-boolean-selector';
import { fetchRegionsOfChina } from '../server-api/common';
import range from '../utils/range'

const YEARS_OLD = range(18, 71).map(v => ({
  value: v,
  label: `${v}岁`,
}))

const EDUCATION = [
  {
    "value": "博士",
    "label": "博士"
  },
  {
    "value": "硕士",
    "label": "硕士"
  },
  {
    "value": "本科",
    "label": "本科"
  },
  {
    "value": "专科",
    "label": "专科"
  },
  {
    "value": "中专",
    "label": "中专"
  },
  {
    "value": "高中",
    "label": "高中"
  },
  {
    "value": "初中",
    "label": "初中"
  },
  {
    "value": "小学",
    "label": "小学"
  }
]

export default createForm()(function PubJobDemand(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const { getFieldProps, getFieldValue } = props.form
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '求职发布'
  }, [])
  React.useEffect(() => {
    fetchRegionsOfChina()
      .then(res => {
        if (res.code === 1000) {
          setRegions(res.data)
        }
        if (id) {
          return fetchJobDemandDetail(id)
            .then(res => {
              const d = res.data.data
              props.form.setFieldsValue({
                jobname: d.title,
                name: d.name,
                sex: d.sex || [1],
                yearsold: [d.age],
                education: [d.education],
                experience: d.workExperience,
                phone: d.phone,
                hidePersonal: d.hidePhone === 1,
                payment: d.expectedSalary,
                region: d.location ? d.location.split(',').map(n => parseInt(n)) : undefined,
                content: d.content,
              })
            })
        }
      })
      .catch(err => {
        Toast.info(err.message, 2, null, false)
      })
  }, [])

  return (
    <div className={s.page}>
      <FormRow
        title="期望职位"
        required
        inputProps={{
          placeholder: "请输入您期望的职位名称",
          ...getFieldProps('jobname', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: '请输入期望职位',
              }
            ],
          })
        }}
      />
      <FormSet
        title="基本信息"
      >
        <div className={s.smallRow}>
          <div className={s.smallRowItem}>
            <FormRow
              title="姓名"
              required
              showBorder
              inputProps={{
                placeholder: "",
                ...getFieldProps('name', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: '请输入姓名',
                    }
                  ],
                })
              }}
            />
          </div>
          <div className={s.smallRowItem}>
            <Picker
              data={[
                {
                  value: 1,
                  label: '男',
                },
                {
                  value: 2,
                  label: '女',
                },
              ]}
              cols={1}
              title="请选择性别"
              {...getFieldProps('sex', {
                initialValue: [1],
                rules: [
                  {
                    required: true,
                    message: '请选择性别',
                  }
                ],
              })}
            >
              <FormPickerChild
                title="性别"
                placeholder=""
                showBorder
                required
                value={getFieldValue('sex')}
              />
            </Picker>
          </div>
        </div>
        <div className={s.smallRow}>
          <div className={s.smallRowItem}>
            <Picker
              data={YEARS_OLD}
              cols={1}
              title="请选择年龄"
              {...getFieldProps('yearsold', {
                initialValue: [25],
                rules: [
                  {
                    required: true,
                    message: '请选择年龄',
                  }
                ],
              })}
            >
              <FormPickerChild
                title="年龄"
                placeholder=""
                showBorder
                required
                value={getFieldValue('yearsold')}
              />
            </Picker>
          </div>
          <div className={s.smallRowItem}>
            <Picker
              data={EDUCATION}
              cols={1}
              title="请选择学历"
              {...getFieldProps('education', {
                rules: [
                  // {
                  //   required: true,
                  //   message: '请选择学历',
                  // }
                ],
              })}
            >
              <FormPickerChild
                title="学历"
                showBorder
                placeholder=""
                // required
                value={getFieldValue('education')}
              />
            </Picker>
          </div>
        </div>
        <FormRow
          title="工作经验"
          // required
          showBorder
          inputProps={{
            placeholder: "请输入您的工作经验",
            ...getFieldProps('experience', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入您的工作经验',
                // }
              ],
            })
          }}
        ></FormRow>
        <FormRow
          title="联系方式"
          required
          inputProps={{
            placeholder: "请输入您的联系联系方式",
            ...getFieldProps('phone', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '请输入您的联系联系方式',
                }
              ],
            })
          }}
        >
          <div className={s.isHidden}>
            <FormBooleanSeletor
              title="是否隐藏个人联系电话："
              {
                ...getFieldProps('hidePersonal', {
                  initialValue: true,
                })
              }
            />
          </div>
        </FormRow>
      </FormSet>
      <FormSet
        title="主要信息"
      >
        <FormRow
          title="期望薪资"
          // required
          showBorder
          inputProps={{
            placeholder: "输入您期望的薪资范围",
            ...getFieldProps('payment', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入期望薪资',
                // }
              ],
            })
          }}
        />
        <Picker
          data={regions}
          cols={3}
          title="请选择地区"
          {...getFieldProps('region', {
            rules: [
              // {
              //   required: true,
              //   message: '请选择工作地区',
              // }
            ],
          })}
        >
          <FormPickerChild
            title="期望地区"
            placeholder="请输入期望的工作地区"
            // required
            value={getFieldValue('region')}
          />
        </Picker>
      </FormSet>
      <div className={classNames(s.field, s.content)}>
        <div className={s.fieldTitle}><span style={{
          visibility: 'hidden',
        }}>*</span>工作经历</div>
        <div className={s.textAreaC}>
          <textarea
            className={s.textarea}
            placeholder="填写您的工作经历"
            {...getFieldProps('content', {
              rules: [
                // {
                //   required: true,
                //   message: '请填写工作经历',
                // },
              ]
            })}
          >
          </textarea>
        </div>
      </div>
      <SubmitPublish
        onClick={() => {
          props.form.validateFields((error, value) => {
            if (error) {
              const firstKey = Object.keys(error)[0]
              Toast.info(error[firstKey].errors[0].message, 2, null, false)
              return
            }
            const params = {
              imageType: 1,
              age: value.yearsold[0],
              content: value.content,
              education: value.education ? value.education[0] : undefined,
              expectedSalary: value.payment,
              hidePhone: value.hidePersonal ? 1 : 2,
              location: value.region ? value.region.toString() : '',
              name: value.name,
              phone: value.phone,
              sex: value.sex[0],
              title: value.jobname,
              workExperience: value.experience,
            }
            let api = submitPubJobDemand
            if (id) {
              params.id = id
              api = updateJobDemand
            }
            Toast.loading('正在发布', 0, null, true)
            api(params)
              .then(res => {
                Toast.hide()
                Toast.success('发布成功', 2, () => {
                  native.navigateBack()
                }, true)
              })
              .catch(e => {
                Toast.hide()
                if (e.code !== 1006) {
                  Toast.fail(e.message || '网络错误', 1.5, null, false)
                }
              })
          })
        }}
      >
        提交审核
      </SubmitPublish>
    </div>
  )
})