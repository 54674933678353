import React from "react";
import s from "./Toggle.module.scss";

import like2x from "../images/like@2x.png";
import like3x from "../images/like@3x.png";
import liked2x from "../images/liked@2x.png";
import liked3x from "../images/liked@3x.png";
import collectI2x from "../images/collect-i@2x.png";
import collectI3x from "../images/collect-i@3x.png";
import collectActiveI2x from "../images/collect-active-i@2x.png";
import collectActiveI3x from "../images/collect-active-i@3x.png";

/**
 * 点赞
 * 收藏
 */

const imgs = {
  like: {
    "2x": like2x,
    "3x": like3x
  },
  liked: {
    "2x": liked2x,
    "3x": liked3x
  },
  collect: {
    "2x": collectI2x,
    "3x": collectI3x
  },
  collectd: {
    "2x": collectActiveI2x,
    "3x": collectActiveI3x
  }
};

export default function Toggle(props) {
  const type = props.type;

  const [atts, setAttrs] = React.useState(props);

  function handleToggle() {
    props.async(atts, () => {
      if (atts["status"] == 1) {
        let nums = atts["num"];
        setAttrs({
          ...atts,
          status: 2,
          num: nums + 1
        });
      } else if (atts["status"] == 2) {
        let nums = atts["num"];
        setAttrs({
          ...atts,
          status: 1,
          num: nums - 1
        });
      }
    });
  };

  const imgSrc = imgs[`${type}${atts["status"] == 2 ? "d" : ""}`];
  const imgSrc2X = imgSrc["2x"];
  const imgSrc3X = imgSrc["3x"];

  return <div className={s.toggle} onClick={handleToggle}>
    <img
      src={imgSrc2X}
      srcSet={`${imgSrc2X} 2x, ${imgSrc3X} 3x`}
      alt=""
    />
    <span>{atts["num"]}</span>
  </div>;
}

Toggle.defaultProps = {
  type: "like"
};
