import React from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import s from './ForumMachineDetailHeader.module.scss'

export default function ForumMachineDetailHeader(props) {
  return (
    <div className={s.header}>
      <div className={s.titleC}>
        <div className={classNames(s.circle, {
          [s.blue]: props.blue,
        })} />
        <div className={s.title}>{props.title}</div>
      </div>
      <div className={s.subTitle}>{props.subtitle}</div>
      <div className={s.footer}>
        <div>{props.view}人浏览</div>
        <div>发布时间: {dayjs(props.createdAt).format('YYYY-MM-DD')}</div>
      </div>
    </div>
  )
}