import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.scss';
import PubTech from './pages/PubTech'
import PubSupply from './pages/PubSupply'
import PubDemand from './pages/PubDemand'
import { StoreProvider } from './mobx/store'
import PubJobDemand from './pages/PubJobDemand'
import PubJob from './pages/PubJob'
import SupplyDetail from './pages/SupplyDetail'
import DemandDetail from './pages/DemandDetail'
import JobDemandDetail from './pages/JobDemandDetail'

import TechnologyExchange from "./pages/Tabs/TechnologyExchange"
import Recruitment from "./pages/Tabs/Recruitment"
import ApplyForJob from "./pages/Tabs/ApplyForJob"
import MachinelRequires from "./pages/Tabs/MachinelRequires";
import MachinelSupply from "./pages/Tabs/MachinelSupply";
import JobDetail from './pages/JobDetail';
import TechDetail from './pages/TechDetail';
import Search from "./pages/Search";
import Invitation from './pages/Invitation';
import OpenApp from './pages/OpenApp/OpenApp';

function App() {
  return (
    <StoreProvider>
      <div className="App">
        <Router>
          <Switch>
            {/* <Route exact path="/">
            </Route> */}
            <Route path="/pubtech">
              <PubTech />
            </Route>
            <Route path="/pubsupply">
              <PubSupply />
            </Route>
            <Route path="/pubdemand">
              <PubDemand />
            </Route>
            <Route path="/pubjobdemand">
              <PubJobDemand />
            </Route>
            <Route path="/pubjob">
              <PubJob />
            </Route>
            <Route path="/supplydetail">
              <SupplyDetail />
            </Route>
            <Route path="/demanddetail">
              <DemandDetail />
            </Route>
            <Route path="/jobdemanddetail">
              <JobDemandDetail />
            </Route>
            <Route path="/jobdetail">
              <JobDetail />
            </Route>
            <Route path="/techdetail">
              <TechDetail />
            </Route>
            <Route path="/recruitment">
              <Recruitment />
            </Route>
            <Route path="/applyforjob">
              <ApplyForJob />
            </Route>
            <Route path="/machinelrequires">
              <MachinelRequires />
            </Route>
            <Route path="/machinelsupply">
              <MachinelSupply />
            </Route>
            <Route path="/technologydxchange">
              <TechnologyExchange />
            </Route>
            <Route path="/search">
              <Search />
            </Route>
            <Route path="/invitation">
              <Invitation />
            </Route>
            <Route path="/openapp">
              <OpenApp />
            </Route>
          </Switch>
        </Router>
      </div>
    </StoreProvider>
  );
}

export default App;
