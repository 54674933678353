import React from "react";
import ReactDOM from "react-dom";
import { ListView, PullToRefresh, ActivityIndicator } from "antd-mobile";
import s from "./TabsListView.module.scss";

const dataBackground = {
  backgroundColor: "#F4F4F4"
};

const dataSourceOrigin = new ListView.DataSource({
  rowHasChanged: (row1, row2) => row1 !== row2
});

export default class TabsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: dataSourceOrigin,
      isLoading: true,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      height: document.documentElement.clientHeight * 3 / 4
    };
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.subTabKey !== this.props.subTabKey) || (nextProps.searchKey !== this.props.searchKey)) {
      console.log("更新", nextProps);
      this.clones = {};
      this.setState({
        isLoading: true,
        pageIndex: 1,
        dataSource: dataSourceOrigin
      }, () => {
        this.getNextList();
      });
    }
  }

  componentDidMount() {
    const hei = document.documentElement.clientHeight - ReactDOM.findDOMNode(this.lv).parentNode.offsetTop;
    this.setState({
      height: hei
    }, this.getNextList);
  }

  getNextList = async () => {
    const res = await this.props.fetchFunc({
      // categoryId: this.props.subTabKey,
      categoryId: this.props.categoryID,
      page: {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize
      },
      searchParam: this.props.searchKey
    });

    if (res.data.code === 1000) {
      const dataBlob = {};
      res.data.data.list.forEach(e => dataBlob[`${e.id}-${e.userId}`] = e);

      this.clones = {
        ...(this.state.refreshing ? {} : this.clones),
        ...dataBlob
      };

      const nextDataSource = this.state.dataSource.cloneWithRows(this.clones);

      this.setState({
        isLoading: false,
        dataSource: nextDataSource,
        hasMore: nextDataSource.getRowCount() >= res.data.data.total,
        pageIndex: this.state.pageIndex + 1,
        refreshing: false
      });
      if (this.props.sideEffect) {
        this.props.sideEffect(this.clones);
      }
      ;
    }
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
      isLoading: true,
      pageIndex: 1
    }, this.getNextList);
  };

  onEndReached = () => {
    if (this.state.isLoading || this.state.hasMore) {
      return;
    }
    this.setState({
      isLoading: true
    });
    this.getNextList();
  };

  renderFooter = () => {
    const {
      isLoading,
      refreshing,
      dataSource
    } = this.state;
    return <div className={s.footer}>
      {
        (isLoading && !refreshing) ?  <ActivityIndicator className={s.center} animating /> : dataSource.getRowCount() ? "我们也是有底线的哦～" : "暂无数据"
      }
    </div>;
  };

  renderSeparator = (sectionID, rowID) => {
    return <div
      key={`${sectionID}-${rowID}`}
      className={s.separator}
    />;
  };

  renderRow = (rowData, sectionID, rowID) => {
    return this.props.renderRow(rowData, rowID);
  };

  render() {
    const listStyle = {
      height: this.state.height,
      overflow: "auto",
      background: "#fff"
    };

    return (
      <ListView
        ref={el => this.lv = el}
        style={listStyle}
        contentContainerStyle={dataBackground}
        sectionBodyClassName={s.listDataArea}
        renderRow={this.renderRow}
        renderFooter={this.renderFooter}
        // renderSeparator={this.renderSeparator}
        dataSource={this.state.dataSource}
        pageSize={4}
        scrollRenderAheadDistance={500}
        onEndReached={this.onEndReached}
        onEndReachedThreshold={50}
        pullToRefresh={<PullToRefresh
          damping={45}
          refreshing={this.state.refreshing}
          onRefresh={this.onRefresh}
        />}
      />
    );
  }
}
