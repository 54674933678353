import { serverInst } from "./request";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职
export function fetchCategory(enumType) {
  return serverInst.post('/api/communityCategory/list', {
    communityEventTypeEnum: enumType,
  })
}
export function submitPubTech(data) {
  return serverInst.post('/api/community/saveTechnicalCommunication', {
    ...data,
  })
}
export function updateTech(data) {
  return serverInst.post('/api/community/updateTechnicalCommunication', {
    ...data,
  })
}
export function submitPubSupply(data) {
  return serverInst.post('/api/community/saveSupply', {
    ...data,
  })
}
export function updateSupply(data) {
  return serverInst.post('/api/community/updateSupply', {
    ...data,
  })
}
export function submitPubDemand(data) {
  return serverInst.post('/api/community/saveDemand', {
    ...data,
  })
}
export function updateDemand(data) {
  return serverInst.post('/api/community/updateDemand', {
    ...data,
  })
}
export function submitPubJobDemand(data) {
  return serverInst.post('/api/community/saveApplyJob', {
    ...data,
  })
}
export function updateJobDemand(data) {
  return serverInst.post('/api/community/updateApplyJob', {
    ...data,
  })
}
export function submitPubJob(data) {
  return serverInst.post('/api/community/saveRecruitment', {
    ...data,
  })
}
export function updateJob(data) {
  return serverInst.post('/api/community/updateRecruitment', {
    ...data,
  })
}
export function fetchSupplyDetail(id) {
  return serverInst.post('/api/community/getSupplyDetails', {
    id,
  })
}
export function fetchDemandDetail(id) {
  return serverInst.post('/api/community/getDemandDetails', {
    id,
  })
}
export function fetchJobDemandDetail(id) {
  return serverInst.post('/api/community/getApplyJobDetails', {
    id,
  })
}
export function fetchJobDetail(id) {
  return serverInst.post('/api/community/getRecruitmentDetails', {
    id,
  })
}
export function fetchTechDetail(id) {
  return serverInst.post('/api/community/getTechnicalCommunicationDetails', {
    id,
  })
}
/**
 * 
 * @param {Int} typeid 1:普通交流 2:机械需求 3:机械供给 4:招聘 5:求职
 * @param {Int} id 
 * @param {Boolean} collected 
 */
export function changeCollectedStatus(typeid, id, collected) {
  return serverInst.post('/api/userCollection/updateCollection', {
    objectId: id,
    objectType: typeid,
    updateType: collected ? 1 : 2,
  })
}
/**
 * 
 * @param {Int} typeid 1:普通交流 2:机械需求 3:机械供给 4:招聘 5:求职
 * @param {Int} id 
 * @param {Boolean} like 1:点赞 2:取消点赞
 */
export function changeLikedStatus(typeid, id, liked) {
  return serverInst.post('/api/userCollection/updateCollection', {
    objectId: id,
    objectType: typeid,
    updateType: liked ? 1 : 2,
  })
}
/**
 * 
 * @param {number} articalId 贴子id
 * @param {string} content 评论内容
 * @param {number} parentId 父贴id
 */
export function publishComment(articalId, content, parentId = 0) {
  return serverInst.post('/api/communityComment/saveComment', {
    content,
    parentId,
    objectId: articalId,
    objectType: 1, // 论坛
  })
}
/**
 * 
 * @param {number} articalId 贴子id
 */
export function fetchComments(articalId, pageIndex, pageSize = 15) {
  return serverInst.post('/api/communityComment/newListComment', {
    objectId: articalId,
    objectType: 1, // 论坛
    page: {
      pageIndex,
      pageSize,
    }
  })
}