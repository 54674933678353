import React from 'react'
import classNames from 'classnames'
import s from './form-boolean-selector.module.scss'

function Option(props) {
  return (
    <div className={s.optionC} onClick={props.onClick}>
      <div className={classNames(s.option, {
        [s.checked]: props.checked,
      })}>
        {
          props.checked ?
            <div className={s.checker}></div> : null
        }
      </div>
      <div className={s.optionText}>{props.text}</div>
    </div>
  )
}

function FormBooleanSeletor(props) {
  const [checked, setChecked] = React.useState(props.value)
  return (
    <div className={s.selector} ref={props.innerRef}>
      <div className={s.title}>{props.title}</div>
      <div className={s.optionsGroup}>
        <Option
          checked={checked}
          text="是"
          onClick={() => {
            setChecked(true)
            props.onChange && props.onChange(true)
          }}
        />
        <Option
          checked={!checked}
          text="否"
          onClick={() => {
            setChecked(false)
            props.onChange && props.onChange(false)
          }}
        />
      </div>
    </div>
  )
}
FormBooleanSeletor.defaultProps = {
  value: true,
}

export default React.forwardRef((props, ref) =>
  <FormBooleanSeletor {...props} innerRef={ref} />
)