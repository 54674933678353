import React from "react";
import c from "./common.module.scss";
import s from "./MachinelRequires.module.scss";

import SimilarSearch from "../../components/SimilarSearch";
import TabsWithMenu from "../../components/TabsWithMenu";
import TabsListView from "../../components/TabsListView";
import FixedButton from "../../components/FixedButton";

import { fetchTabCategory, fetchMachinelRequiresList } from "../../server-api/tabs";
import { relativeFromNow } from "../../utils/date";
import { getRdomColor } from "../../utils/color";
import { callUp } from "../../utils/capacity";

import native from "../../utils/native";
import Searcth from "../Search";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职
export default function Main() {
  const [tabs, setTabs] = React.useState([]);
  const [current, setCurrent] = React.useState(0);
  const [loadingCategory, setLoadingCategory] = React.useState(true)

  React.useEffect(() => {
    document.title = "机械需求";

    fetchTabCategory(2)
    .then(res => {
      if (res.data.code === 1000) {
        const origin = res.data.data.list;
        setTabs((Array.isArray(origin) ? origin : []).map((e, index) => ({
          key: e.id,
          title: e.name,
          index: index
        })));
        setLoadingCategory(false)
      }
    });
  }, []);

  const handleGoTo = () => {
    native.openWebview("/pubdemand", {});
  };

  const handleGotoDetail = (id) => {
    native.openWebview("/demanddetail", {id});
  };

  const handleCellPhone = (e, phone) => {
    e.stopPropagation();
    callUp(phone);
  };

  const renderListRow = (record, key) => {
    return <div key={key} className={s.listItem}>
      <div className={s.itemContent} onClick={() => {
        handleGotoDetail(record.id);
      }}>
        <div className={s.top}>
          <div className={s.title}>{record.title}</div>
          <div className={s.date}>{relativeFromNow(record.createAt)}发布</div>
        </div>
        <div className={s.center}>
          { record.brandName && <span>品牌：{record.brandName}</span> }
          { record.model && <span>型号：{record.model}</span> }
          { record.equipmentStatus && <span>新旧度：{record.equipmentStatus}</span> }
          { record.specification && <span>其他：{record.specification}</span> }
        </div>
        <div className={s.footer}>
          <div className={s.salary}>预算价格: {record.expectedSalary ? record.expectedSalary : '面议'}</div>
          {
            record.phone && <div className={c.listItemOperate} onClick={e => {
              handleCellPhone(e, record.phone);
            }}>联系Ta</div>
          }
        </div>
        <div className={s.index}>
          <div className={s.cirleOut} style={{
            background: getRdomColor()
          }}>
            <div className={s.cirleIn}/>
          </div>
        </div>
      </div>
    </div>;
  };

  const [searchKey, setSearchKey] = React.useState("");
  const [showSearch, setShowSearch] = React.useState("none");

  const handleSearchOn = () => {
    setShowSearch("block");
  };

  const handleSearchOff = () => {
    setShowSearch("none");
  };

  const handleReceiveSearchKey = (x) => {
    setSearchKey(x);
  };

  if (loadingCategory) {
    return null
  }

  return <div className={c.page}>
    <div className={c.pageTop}>
      <SimilarSearch
        click={handleSearchOn}
        placeholder={"请根据您的需求输入关键词"}
        value={searchKey}
      />
    </div>
    <div className={c.pageTabs}>
      <TabsWithMenu
        tabs={tabs}
        active={current}
        setTab={setCurrent}
      />
    </div>
    <div className={c.pageContent}>
      <TabsListView
        fetchFunc={fetchMachinelRequiresList}
        renderRow={renderListRow}
        subTabKey={current}
        searchKey={searchKey}
        categoryID={tabs[current].key}
      />
    </div>
    <FixedButton text={"发布需求"} click={handleGoTo}/>
    <Searcth
      show={showSearch}
      onBack={handleSearchOff}
      receiveSearchKey={handleReceiveSearchKey}
    />
  </div>;
}
