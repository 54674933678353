import React from 'react'
import s from './CommentInputBox.module.scss'
import pen2x from '../images/input-pencil@2x.png'
import pen3x from '../images/input-pencil@3x.png'

export default React.forwardRef(function CommentInputBox(props, ref) {
  return (
    <div className={s.commentInputBox}>
      <div className={s.inputbox}>
        <img className={s.pen} src={pen2x} srcSet={`${pen2x} 2x, ${pen3x} 3x`} alt=""/>
        <input
          onClick={props.onClick}
          type="search"
          ref={ref}
          placeholder="我来说几句"
          onChange={props.onChange}
          value={props.value}
          className={s.textarea}
        />
      </div>
      <button className={s.send} onClick={props.onSend}>发送</button>
    </div>
  )
})