import React from "react";
import c from "./common.module.scss";
import s from "./Recruitment.module.scss";

import SimilarSearch from "../../components/SimilarSearch";
import TabsListView from "../../components/TabsListView";
import FixedButton from "../../components/FixedButton";

import { fetchTabCategory, fetchRecruitmentList } from "../../server-api/tabs";
import { fetchRegionsOfChina } from "../../server-api/common";
import { findRegionString } from "../../utils/region";
import { relativeFromNow } from "../../utils/date";
import { callUp } from "../../utils/capacity";

import native from "../../utils/native";
import Searcth from "../Search";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职
export default function Main() {
  // const [tabs, setTabs] = React.useState([]);
  // const [current, setCurrent] = React.useState(0);

  // React.useEffect(() => {
  //   fetchTabCategory(4)
  //   .then(res => {
  //     if (res.data.code === 1000) {
  //       const origin = res.data.data.list;
  //       setTabs((Array.isArray(origin) ? origin : []).map((e, index) => ({
  //         key: e.id,
  //         title: e.name,
  //         index: index
  //       })));
  //     }
  //   });
  // }, []);

  const [region, setRegion] = React.useState([]);

  React.useEffect(() => {
    document.title = "招聘";

    fetchRegionsOfChina().then(res => {
      if (res.code == 1000) {
        setRegion(res.data);
      }
    });
  }, []);

  const handleGoTo = () => {
    native.openWebview("/pubjob", {});
  };

  const handleGotoDetail = (id) => {
    native.openWebview("/jobdetail", {id});
  };

  const handleCellPhone = (e, phone) => {
    e.stopPropagation();
    callUp(phone);
  };

  const renderListRow = (record, key) => {
    return <div key={key} className={s.listItem} onClick={() => {
      handleGotoDetail(record.id);
    }}>
      <div className={s.introduction}>
        <div className={s.position}>
          <div className={s.name}>{record.title}</div>
          <span className={s.salary}>{record.expectedSalary ? record.expectedSalary : '面议'}</span>
        </div>
        <div className={s.industry}>
          {
            record.home && <span>
            {
              findRegionString(region, record.home.split(",").map(n => parseInt(n)), 2)
            }
            </span>
          } 
          {
            record.company && <span>{record.company}</span>
          }
          {
            record.financingStage && <span>{record.financingStage}</span>
          }
        </div>
      </div>
      <div className={s.company}>
        <img src={record.avatar} alt=""/>
        <div className={s.info}>
          <div className={s.content}>
            {record.skillRequirement}
          </div>
          <div>{relativeFromNow(record.createAt)}更新</div>
        </div>
        {
          record.phone && <div className={s.operate} onClick={e => {
            handleCellPhone(e, record.phone);
          }}>
            <span>接受邀请</span>
          </div>
        }
      </div>
    </div>;
  };

  const [searchKey, setSearchKey] = React.useState("");
  const [showSearch, setShowSearch] = React.useState("none");

  const handleSearchOn = () => {
    setShowSearch("block");
  };

  const handleSearchOff = () => {
    setShowSearch("none");
  };

  const handleReceiveSearchKey = (x) => {
    setSearchKey(x);
  };

  return <div className={c.page}>
    <div className={c.pageTop}>
      <SimilarSearch
        click={handleSearchOn}
        placeholder={"请根据您的需求输入关键词"}
        value={searchKey}
      />
    </div>
    <div className={s.pageContent}>
      <TabsListView
        fetchFunc={fetchRecruitmentList}
        renderRow={renderListRow}
        subTabKey={0}
        searchKey={searchKey}
        categoryID={0}
      />
    </div>
    <FixedButton text={"我要招聘"} click={handleGoTo}/>
    <Searcth
      show={showSearch}
      onBack={handleSearchOff}
      receiveSearchKey={handleReceiveSearchKey}
    />
  </div>;
}
