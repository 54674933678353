import React from 'react'
import { action } from 'mobx'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Toast, Flex } from 'antd-mobile'
import qs from 'query-string'
import dayjs from 'dayjs'
import s from './JobDemandDetail.module.scss'
import ConfirmDetailButton from '../components/ConfirmDetailButton'
import { fetchRegionsOfChina } from '../server-api/common';
import { fetchJobDemandDetail, changeCollectedStatus } from '../server-api/forum';
import { useLocation } from 'react-router-dom';
import { DetailContact } from '../components/DetailContact';
import { findRegionString } from '../utils/region';
import { hidePhoneNumber } from '../utils/formatter';
import { parseServerDateTime } from '../utils/date';
import { callUp } from '../utils/capacity';
import DetailRUActions from '../components/DetailRUActions';
import native from '../utils/native'

export default observer(function JobDemandDetail(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const state = useLocalStore(() => {
    return {
      loading: true,
      title: '',
      subtitle: '',
      view: 0,
      createdAt: new Date(),
      phone: '',
      hidePersonal: true,
      region: '',
      content: '',
      collected: false,
    }
  })
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '求职'
    fetchRegionsOfChina()
      .then(r => {
        if (r.code === 1000) {
          setRegions(r.data)
        }

        fetchJobDemandDetail(id)
          .then(action(res => {
            console.log(res)
            const data = res.data.data

            state.loading = false
            state.phone = data.phone
            state.hidePersonal = data.hidePhone === 1
            state.region = data.location ? findRegionString(r.data, data.location.split(',').map(i => parseInt(i))) : ''
            state.createdAt = parseServerDateTime(data.createAt)
            state.content = data.content
            state.avatar = data.avatar
            state.username = data.name
            state.levelName = data.levelName
            state.age = data.age
            state.workExperience = data.workExperience
            state.education = data.education
            state.expectedSalary = data.expectedSalary
            state.createdAt = parseServerDateTime(data.createAt)
            state.jobName = data.title
            // 收藏状态 1:未收藏 2:已收藏
            state.collected = data.collectionStatus === 2
          }))
          .catch(e => Toast.info(e.message, 2, null, false))
      })
      .catch(e => Toast.info(e.message, 2, null, false))
  }, [])
  
  if (state.loading) return null
  return (
    <React.Fragment>
      <div className={s.page}>
        <div className={s.header} style={{
          position: 'relative',
        }}>
          <div className={s.avatarC}>
            <img className={s.avatar} src={state.avatar} alt="" />
          </div>
          <div className={s.headerContent}>
            <Flex>
              <div className={s.userName}>{state.username}</div>
              <div className={s.levelName}>{state.levelName}</div>
            </Flex>
            <div className={s.personlabel}>
              {
                `${state.age}岁 ${state.workExperience ? '|' + state.workExperience : ''} ${state.education ? '|' + state.education : ''}`
              }
            </div>
            <div className={s.headerFoot}>
              <div className={s.expectedSalary}>
                期望薪资: {state.expectedSalary || '面议'}
              </div>
              <div className={s.createdAt}>
                {dayjs(state.createdAt).format('YYYY/MM/DD')}
              </div>
            </div>
          </div>
          <DetailRUActions
            collected={state.collected}
            onCollect={() => {
              changeCollectedStatus(5, id, true)
                .then(() => {
                  state.collected = true
                })
            }}
            onShare={() => {

            }}
            onCancelCollect={() => {
              changeCollectedStatus(5, id, false)
                .then(() => {
                  state.collected = false
                })
            }}
          />
        </div>
        <div className={s.contactBlock}>
          <DetailContact
            entries={[
              {
                key: '本人电话',
                value: state.phone,
                render(v) {
                  const n = state.hidePersonal ? hidePhoneNumber(v) : v
                  return <span className={s.phoneNumber}>{n}</span>
                },
              },
              {
                key: '期望地区',
                value: state.region ? state.region : '--',
              }
            ]}
          />
        </div>
        <div className={s.mainContent}>
          <div className={s.block}>
            <div className={s.blockHeader}>期望职位</div>
            <div className={s.blockContent}>{state.jobName}</div>
          </div>
          <div className={s.block}>
            <div className={s.blockHeader}>工作经历</div>
            <div className={s.blockContent}>{state.content || '未填写任何数据'}</div>
          </div>
        </div>
      </div>
      <ConfirmDetailButton
        onClick={() => {
          callUp(state.phone)
        }}
      >
        联系我们
      </ConfirmDetailButton>
    </React.Fragment>
  )
})