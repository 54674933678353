import { serverInst } from "./request";

// 1:普通交流,2:机械需求 3:机械供给,4:招聘,5:求职

// 类别获取
export function fetchTabCategory(tabKey) {
  return serverInst.post("/api/communityCategory/list", {
    communityEventTypeEnum: tabKey
  });
}

// 1普通交流分页
export function fetchTechnologyExchangeList(data) {
  return serverInst.post("/api/community/listTechnicalCommunication", {
    ...data
  });
}

// 2机械需求分页
export function fetchMachinelRequiresList(data) {
  return serverInst.post("/api/community/listDemand", {
    ...data
  });
}

// 3机械供给
export function fetchMachinelSupplyList(data) {
  return serverInst.post("/api/community/listSupply", {
    ...data
  });
}

// 4招聘
export function fetchRecruitmentList(data) {
  return serverInst.post("/api/community/listRecruitment", {
    ...data
  });
}

// 5求职
export function fetchApplyForJobList(data) {
  return serverInst.post("/api/community/listApplyJob", {
    ...data
  });
}

//1->点赞
export function setIsLiked(data) {
  return serverInst.post("/api/user-like/updateLike", {
    ...data
  });
}

//1->搜索
export function setIsCollection(data) {
  return serverInst.post("/api/userCollection/updateCollection", {
    ...data
  });
}
