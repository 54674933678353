import { serverInst } from "./request";

export function uploadFile(file, progress) {
  var data = new FormData();
  data.append('file', file);
  var config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      progress(percentCompleted)
    },
    timeout: 0,
  };
  return serverInst.post('/api/uploadFile/uploadFile', data, config)
}
export function uploadVideoFile(file, progress) {
  var data = new FormData();
  data.append('file', file);
  var config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      progress(percentCompleted)
    },
    timeout: 0,
  };
  return serverInst.post('/api/uploadFile/videoUploadFile', data, config)
}