import React from 'react'
import { Flex, Icon } from 'antd-mobile'
import classNames from 'classnames'
import s from './form-picker-child.module.scss'

const FieldTitle = (props) =>
  <div className={s.fieldTitle}><span style={{
    visibility: props.required ? 'visible' : 'hidden'
  }}>*</span>{props.children}</div>

export default props => 
  <div className={s.field} onClick={props.onClick}>
    <div className={classNames(s.inner, {
      [s.sep]: props.showBorder,
    })}>
      <Flex className={s.main}>
        <FieldTitle required={props.required}>{props.title}</FieldTitle>
        {
          props.value ?
            <div>{props.extra}</div> :
            <div className={s.placeholder}>{props.placeholder}</div>
        }
      </Flex>
      <Icon color="#929292" type="right" />
    </div>
  </div>