import React from 'react'
import classNames from 'classnames'
import { Picker } from 'antd-mobile'
import { Toast } from 'antd-mobile'
import { createForm } from 'rc-form'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import FormPickerChild from '../components/form-picker-child'
import FormRow from '../components/form-row'
import s from './PubJob.module.scss'
import SubmitPublish from '../components/submit-publish';
import native from '../utils/native'
import { submitPubJob, fetchJobDetail, updateJob } from '../server-api/forum';
import FormSet from '../components/form-set';
import FormBooleanSeletor from '../components/form-boolean-selector';
import { fetchRegionsOfChina } from '../server-api/common';

export default createForm()(function PubJob(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const { getFieldProps, getFieldValue } = props.form
  const [regions, setRegions] = React.useState([])
  React.useEffect(() => {
    document.title = '职位发布'
  }, [])
  React.useEffect(() => {
    fetchRegionsOfChina()
      .then(res => {
        if (res.code === 1000) {
          setRegions(res.data)
        }
        if (id) {
          return fetchJobDetail(id)
            .then(res => {
              const d = res.data.data
              console.log(d)
              props.form.setFieldsValue({
                jobname: d.title,
                company: d.company,
                phone: d.phone,
                hidePersonal: d.hidePhone === 1,
                payment: d.expectedSalary,
                region: d.home.split(',').map(n => parseInt(n)),
                workAddress: d.location,
                finance: d.financingStage,
                workDetail: d.jobDetails,
                skill: d.skillRequirement,
                team: d.remark,
              })
            })
        }
      })
      .catch(err => {
        Toast.info(err.message, 2, null, false)
      })
  }, [])

  return (
    <div className={s.page}>
      <FormRow
        title="招聘职位"
        required
        showBorder
        inputProps={{
          placeholder: "请输入所招聘的职位名称",
          ...getFieldProps('jobname', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: '请输入招聘职位',
              }
            ],
          })
        }}
      />
      <FormRow
        title="薪资待遇"
        // required
        inputProps={{
          placeholder: "请输入该职位薪资待遇",
          ...getFieldProps('payment', {
            initialValue: '',
            rules: [
              // {
              //   required: true,
              //   message: '请输入薪资待遇',
              // }
            ],
          })
        }}
      />
      <FormSet
        title="基本信息"
      >
        <FormRow
          title="公司名称"
          required
          showBorder
          inputProps={{
            placeholder: "请输入贵公司全称",
            ...getFieldProps('company', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '请输入公司名称',
                }
              ],
            })
          }}
        />
        <Picker
          data={regions}
          cols={3}
          title="请选择所在城市"
          {...getFieldProps('region', {
            rules: [
              {
                required: true,
                message: '请选择所在城市',
              }
            ],
          })}
        >
          <FormPickerChild
            title="所在城市"
            placeholder="请选择贵司所在城市"
            showBorder
            required
            value={getFieldValue('region')}
          />
        </Picker>
        <FormRow
          title="融资阶段"
          // required
          inputProps={{
            placeholder: "贵司融资情况",
            ...getFieldProps('finance', {
              initialValue: '',
              rules: [
                // {
                //   required: true,
                //   message: '请输入融资阶段',
                // }
              ],
            })
          }}
        />
      </FormSet>
      <div className={s.sep}>
        <div className={classNames(s.field, s.content)}>
          <div className={s.fieldTitle}><span style={{
            visibility: 'hidden',
          }}>*</span>工作地址</div>
          <div className={classNames(s.textAreaC, s.showBorder)}>
            <textarea
              className={s.textarea}
              rows="4"
              placeholder="请输入工作的详细地址"
              {...getFieldProps('workAddress', {
                rules: [
                  // {
                  //   required: true,
                  //   message: '请填写工作地址',
                  // },
                ]
              })}
            >
            </textarea>
          </div>
        </div>
        <FormRow
          title="联系方式"
          required
          inputProps={{
            placeholder: "请输入您的联系方式",
            ...getFieldProps('phone', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '请输入您的联系方式',
                }
              ],
            })
          }}
        >
          <div className={s.isHidden}>
            <FormBooleanSeletor
              title="是否隐藏个人联系电话："
              {
                ...getFieldProps('hidePersonal', {
                  initialValue: true,
                })
              }
            />
          </div>
        </FormRow>
      </div>
      <FormSet
        title="主要信息"
      >
        <div className={classNames(s.field, s.content)}>
          <div className={s.fieldTitle}><span style={{
            visibility: 'hidden',
          }}>*</span>职位详情</div>
          <div className={classNames(s.textAreaC, s.showBorder)}>
            <textarea
              className={s.textarea}
              rows="4"
              placeholder="请输入该职位的详情"
              {...getFieldProps('workDetail', {
                rules: [
                  // {
                  //   required: true,
                  //   message: '请输入职位详情',
                  // },
                ]
              })}
            >
            </textarea>
          </div>
        </div>
        <div className={classNames(s.field, s.content)}>
          <div className={s.fieldTitle}><span style={{
            visibility: 'hidden',
          }}>*</span>技能要求</div>
          <div className={classNames(s.textAreaC, s.showBorder)}>
            <textarea
              className={s.textarea}
              rows="4"
              placeholder="请输入20个字符以内的关键词"
              {...getFieldProps('skill', {
                rules: [
                  // {
                  //   required: true,
                  //   message: '请输入技能要求',
                  // },
                ]
              })}
            >
            </textarea>
          </div>
        </div>
        <div className={classNames(s.field, s.content)}>
          <div className={s.fieldTitle}><span style={{
            visibility: 'hidden',
          }}>*</span>团队介绍</div>
          <div className={classNames(s.textAreaC)}>
            <textarea
              className={s.textarea}
              rows="4"
              placeholder="填写贵公司的团队及项目介绍"
              {...getFieldProps('team', {
                rules: [
                  // {
                  //   required: true,
                  //   message: '请输入团队介绍',
                  // },
                ]
              })}
            >
            </textarea>
          </div>
        </div>
      </FormSet>
      <SubmitPublish
        onClick={() => {
          props.form.validateFields((error, value) => {
            if (error) {
              const firstKey = Object.keys(error)[0]
              Toast.info(error[firstKey].errors[0].message, 2, null, false)
              return
            }
            const params = {
              imageType: 1,
              content: value.content,
              company: value.company,
              expectedSalary: value.payment,
              financingStage: value.finance,
              hidePhone: value.hidePersonal ? 1 : 2,
              jobDetails: value.workDetail,
              location: value.workAddress,
              phone: value.phone,
              remark: value.team,
              skillRequirement: value.skill,
              title: value.jobname,
              home: value.region.toString(),
            }
            let api = submitPubJob
            if (id) {
              params.id = id
              api = updateJob
            }
            Toast.loading('正在发布', 0, null, true)
            api(params)
              .then(res => {
                Toast.hide()
                Toast.success('发布成功', 2, () => {
                  native.navigateBack()
                }, true)
              })
              .catch(e => {
                Toast.hide()
                if (e.code !== 1006) {
                  Toast.fail(e.message || '网络错误', 1.5, null, false)
                }
              })
          })
        }}
      >
        提交审核
      </SubmitPublish>
    </div>
  )
})