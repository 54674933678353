import React from "react";
import s from "./DetailRUActions.module.scss";
import collectI2x from "../images/collect-i@2x.png";
import collectI3x from "../images/collect-i@3x.png";
import collectActiveI2x from "../images/collect-active-i@2x.png";
import collectActiveI3x from "../images/collect-active-i@3x.png";
// import shareI2x from "../images/share-i@3x.png";
// import shareI3x from "../images/share-i@3x.png";

export default function DetailRuActions(props) {
  return (
    <div className={s.detailRUActions}>
      {props.collected ? (
        <img
          onClick={props.onCancelCollect}
          className={s.collect}
          src={collectActiveI2x}
          srcSet={`${collectActiveI2x} 2x, ${collectActiveI3x} 3x`}
          alt=""
        />
      ) : (
        <img
          onClick={props.onCollect}
          className={s.collect}
          src={collectI2x}
          srcSet={`${collectI2x} 2x, ${collectI3x} 3x`}
          alt=""
        />
      )}
      {/* <img
        onClick={props.onShare}
        className={s.share}
        src={shareI2x}
        srcSet={`${shareI2x} 2x, ${shareI3x} 3x`}
        alt=""
      /> */}
    </div>
  );
}
