export function selectFile(accept) {
  let $input = document.getElementById('hiddenInput')
  if (!$input) {
    $input = document.createElement('input')
    $input.id = 'hiddenInput'
    $input.className = 'hiddenInput'
    $input.type = 'file'
    document.body.appendChild($input)
  }
  if (accept) {
    $input.accept = accept
  } else {
    $input.accept = ''
  }
  return new Promise(resolve => {
    $input.onchange = function(e) {
      const file = e.currentTarget.files[0]
      resolve({
        file,
        isVideo: /video/.test(file.type),
        url: URL.createObjectURL(file),
      })
      $input.value = ''
    }
    $input.click()
  })
}