export function findRegionString(region, regionsID, level = 3) {
  if (region.length === 0 || level === 0) {
    return ''
  }
  const i = region.findIndex(r => r.value === regionsID[0])
  if (i !== -1) {
    const r = region[i]
    return r.label + findRegionString(r.children, regionsID.slice(1), level - 1)
  }
  return ''
}