import React from 'react'
import classNames from 'classnames'
import { Picker } from 'antd-mobile'
import { Toast } from 'antd-mobile';
import qs from 'query-string'
import shortid from 'shortid'
import { createForm } from 'rc-form'
import FormPickerChild from '../components/form-picker-child'
import s from './PubTech.module.scss'
import UploadImages from '../components/upload-images';
import SubmitPublish from '../components/submit-publish';
import native from '../utils/native'
import {
  fetchCategory,
  submitPubTech,
  fetchTechDetail,
  updateTech,
} from "../server-api/forum";
import { useLocation } from 'react-router-dom';

export default createForm()(function PubTech(props) {
  const location = useLocation()
  const id =  parseInt(qs.parse(location.search).id)
  const { getFieldProps, getFieldValue } = props.form
  const [categories, setCategories] = React.useState([])
  React.useEffect(() => {
    document.title = '交流发布'
  }, [])
  React.useEffect(() => {
    fetchCategory(1)
      .then(res => {
        setCategories(res.data.data.list.map(item => ({
          value: item.id,
          label: item.name,
        })))
        if (id) {
          return fetchTechDetail(id)
            .then(res => {
              const d = res.data.data
              const uploadFiles = d.uploadFiles.map(up => ({
                ossURL: up.url,
                progress: 100,
                uploaded: true,
                localurl: up.thumbnail,
                name: '',
                id: shortid.generate(),
                isVideo: up.fileType === 3,
                failed: false,
                file: null,
              }))
              if (uploadFiles.length > 0 && uploadFiles[0].isVideo) {
                const video = uploadFiles[0]
                props.form.setFieldsValue({
                  // category: 
                  media: {
                    video,
                    images: [],
                  }
                })
              } else {
                props.form.setFieldsValue({
                  media: {
                    video: null,
                    images: uploadFiles
                      .filter(up => up.fileType !== 3),
                  }
                })
              }
              props.form.setFieldsValue({
                category: [''+d.categoryId],
                content: d.content,
              })
            })
        }
      })
      .catch(err => {
        Toast.info(err.message, 2, null, false)
      })
  }, [])

  return (
    <div className={s.page}>
      <form>
      <Picker
        data={categories}
        title="交流分类"
        cols={1}
        {...getFieldProps('category', {
          rules: [
            {
              required: true,
              message: '请选择分类',
            }
          ],
        })}
      >
        <FormPickerChild
          title="交流分类"
          placeholder="请选择所交流的类别"
          value={getFieldValue('category')}
          required
        />
      </Picker>
      </form>
      <UploadImages
        {...getFieldProps('media', {
          rules: [
            {
              validator(rule,value,callback){
                if (!value) {
                  return new Error('请上传图片')
                }
                if (value.video) {
                  if (value.video.failed) {
                    return new Error('视频上传失败')
                  }
                  if (!value.video.uploaded) {
                    return new Error('请等待上传完成')
                  }
                } else {
                  for (let i = 0, len = value.images.length; i < len; ++i) {
                    if (value.images[i].failed) {
                      return new Error('图片上传失败')
                    }
                    if (!value.images[i].uploaded) {
                      return new Error('请等待上传完成')
                    }
                  }
                }
                return true
              }
            }
          ]
        })}
      />
      <div className={classNames(s.field, s.content)}>
        <div className={s.fieldTitle}><span>*</span>发布我的想法和问题</div>
        <div className={s.textAreaC}>
          <textarea
            className={s.textarea}
            placeholder="请输入200个以内的字符"
            {...getFieldProps('content', {
              rules: [
                {
                  required: true,
                  message: '请填写内容',
                },
                {
                  max: 200,
                  message: '内容不可超过200字'
                }
              ]
            })}
          >
          </textarea>
        </div>
      </div>
      <SubmitPublish
        onClick={() => {
          // native.navigateBack()
          props.form.validateFields((error, value) => {
            if (error) {
              const firstKey = Object.keys(error)[0]
              Toast.info(error[firstKey].errors[0].message, 2, null, false)
              return
            }
            const params = {
              // 图片类型 1:无图片 2：图片 3：视频
              imageType: value.media.video ? 3 : 2,
              categoryId: value.category[0],
              content: value.content,
              imageUrls: value.media.video ? [value.media.video.ossURL] : value.media.images.map(({ ossURL }) => ossURL),
            }
            let api = submitPubTech
            if (id) {
              params.id = id
              api = updateTech
            }
            Toast.loading('正在发布', 0, null, true)
            api(params)
              .then(res => {
                  Toast.hide()
                  Toast.success('发布成功', 1.5, () => {
                    native.navigateBack()
                  }, true)
              })
              .catch(e => {
                Toast.hide()
                if (e.code !== 1006) {
                  Toast.fail(e.message || '网络错误', 1.5, null, false)
                }
              })
          })
        }}
      >
        提交审核
      </SubmitPublish>
    </div>
  )
})