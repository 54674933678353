import React from 'react'
import classNames from 'classnames'
import s from './DetailBottomPlaceholder.module.scss'

export default function DetailBottomPlaceholder(props) {
  return (
    <div className={classNames(s.pl, {
      [s.borderTop]: props.showBorder,
    })}>
      我们也是有底线的哦～
    </div>
  )
}