import React from 'react'
import s from './ConfirmDetailButton.module.scss'
import icon2x from '../images/contactBtnI@2x.png'
import icon3x from '../images/contactBtnI@2x.png'

export default function ConfirmDetailButton(props) {
  return (
    <div className={s.button} onClick={props.onClick}>
      <img src={icon2x} srcSet={`${icon2x} 2x, ${icon3x} 3x`} alt="" />
      <div className={s.text}>{props.children}</div>
    </div>
  )
}